import { PlayerTabTypes } from "@/components/Players/DesktopPlayer/DesktopPlayer.interface";
import {
  MediaMetaData,
  PlacementTypes,
  PlayerStatusTypes,
  SlugType,
} from "@/helpers/pageHelpers/Common/interface";

export enum ScrollDirection {
  Up = "up",
  Down = "down",
}

export enum HorizontalScrollDirection {
  Left = "left",
  Right = "right",
}

export enum FeedType {
  Main = "main",
  Personal = "personal",
}

export enum SearchCategory {
  Best = "best",
  Articles = "articles",
  Podcasts = "podcasts",
  Episodes = "episodes",
  Authors = "redactors",
}

export enum AuthFormState {
  Login = "login",
  Register = "register",
  ThankYou = "thankYou",
  Reset = "reset",
}

export interface PreviousTrackData {
  slug?: string;
  title: string;
  subTitle: string;
  artwork: string;
  time: number;
  audioDuration: number;
}

export interface AudioElement {
  setSource(
    source: string,
    metadata: MediaMetaData,
    time: number
  ): Promise<void>;
  setCurrentTime(time: number): Promise<void>;
  pause(): Promise<void>;
  play(): Promise<void>;
  setVolume(volume: number): Promise<void>;
  setRate(rate: number): Promise<void>;
  updateMetadata(metadata: MediaMetaData): Promise<void>;
}

export enum StatusType {
  InProgress = "in_progress",
  Finished = "finished",
}

export interface PlayerStoreInterface {
  audioElement?: AudioElement;
  audioBuffer: any;
  radioSource: string;
  currentSource: string;
  previousSource: string;
  previousData: PreviousTrackData;
  hasBeenActivated: boolean;
  playerStatus: PlayerStatusTypes;
  showAdditionalControls: boolean;
  hidePlayer: boolean;
  activePlayerTab: PlayerTabTypes;
  uiVolume: [number];
  slug: string;
  podcast: { slug: SlugType };
  title: string;
  subTitle: string;
  artwork: string;
  volume: number;
  rate: number;
  authors?: string[] | [string] | [];
  tags?: string[] | [string] | [];
  publishedAt?: string;
  audioDuration: number;
  audioCurrentTime: number;
  radioTitle: string;
  radioSubTitle: string;
  radioArtwork: string;
  radioArtworkLarge: string;
  isSchedulePlaying: boolean;
  isScheduleAvailable: boolean;
  scheduleBelongsToPortal: boolean;
  scheduleTitle: string;
  scheduleArtworkUrl: string;
  scheduleSlug: string;
  scheduleStart: string;
  scheduleEnd: string;
  listenedEpisodes?: {
    [key: string]: { status: number; status_type: StatusType };
  }[];
  placement?: PlacementTypes;
  SBMFFestivalTitle: string;
  SBMFFestivalSubTitle: string;
}

export interface UIStoreInterface {
  hideSubMenus: boolean;
  clampNavHeader: boolean;
  menuHeight: number;
  feedType: FeedType;
  userOnboarded: boolean;
  userLoggedIn: boolean;
  firstRender: boolean;
  authModalOpen: boolean;
  authFormState: AuthFormState;
  alternateColors: boolean;
  searchInitiated: boolean;
  searchCategory: SearchCategory;
  searchResults: any;
  perksModalStep: number;
  articleSlug: SlugType;
  articleBookmarked: boolean;
  articleScrollDirection: ScrollDirection;
  articleLength: number;
  articleScrollPositionY: number;
  articleScrollRelativeDistance: number;
  commentsCount: number;
  oneSingalId: string;
  oneSingalTags: any;
  userID: any;
  bookmarkedEpisodes?: string[];
  isShareModalOpen?: boolean;
}

export interface HistoryInterface {
  artworkUrl: string;
  startTime: string;
  title: string;
}

export interface RadioStoreInterface {
  currentTrack: string;
  originalHistory: Array<HistoryInterface>;
}
