import React, { useEffect, useRef, useState } from "react";
import { useStoreState } from "pullstate";
import classnames from "classnames";

import { psChangeRate, PlayerStore } from "@/store/PlayerStore";
import ArrowIcon from "@/icons/arrow-down-small.svg";

import styles from "./PlaybackRateButton.module.scss";

interface PlaybackRateButtonInterface {
  withMenu?: boolean;
  className?: string;
}

export const PlaybackRateButton: React.FC<PlaybackRateButtonInterface> = ({
  withMenu,
  className,
}) => {
  const isMobileApp = process.env.NEXT_PUBLIC_MOBILE_APP === "true";
  const isPlaybackRateButtonAvailable =
    !isMobileApp || (isMobileApp && window.RNisSetRateAvailable);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const { rate } = useStoreState(PlayerStore, (store) => store);
  const playbackRate = [0.5, 0.8, 1, 1.2, 1.5, 2, 3];
  const menuRef = useRef(null);
  const iconButtonRef = useRef(null);

  const changeRate = () => {
    const activeRateIndex = playbackRate.indexOf(rate);
    const nextIndex =
      activeRateIndex + 1 >= playbackRate.length ? 0 : activeRateIndex + 1;
    PlayerStore.update(psChangeRate(playbackRate[nextIndex]));
  };

  const openMenu = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const chooseRate = (rateIndex: number) => {
    PlayerStore.update(psChangeRate(playbackRate[rateIndex]));
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !iconButtonRef.current.contains(event.target)
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [menuRef, isMenuOpen]);

  if (!isPlaybackRateButtonAvailable) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <button
        className={classnames(styles.button, className, "playback-rate")}
        onClick={withMenu ? openMenu : changeRate}
        ref={iconButtonRef}
      >
        {rate}x
        {withMenu && <ArrowIcon className={!isMenuOpen && styles.icon} />}
      </button>
      {isMenuOpen && (
        <div className={styles.menu} ref={menuRef}>
          {playbackRate.map((rateElement, index) => (
            <button
              key={rateElement}
              className={classnames(
                styles.rate,
                rateElement === rate && styles.active
              )}
              onClick={() => chooseRate(index)}
            >
              {rateElement}x
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default PlaybackRateButton;
