import React, { createElement } from "react";
import classnames from "classnames";

import { ComponentProps } from "@/helpers/componentProps";

import styles from "./Typography.module.scss";

export type Variant =
  | "hero"
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "secondary"
  | "heading"
  | "small"
  | "medium"
  | "large";

interface TypographyProps extends ComponentProps {
  variant?: Variant;
  component?: string;
  className?: string;
  dateTime?: string;
}

//@todo: Update dateTime logic for only time component
export const Typography: React.FC<TypographyProps> = ({
  children,
  variant,
  component,
  className,
  dateTime,
}) => {
  const variantMapping: { [key in Variant]: string } = {
    hero: "hero",
    h1: "visually-h1",
    h2: "visually-h2",
    h3: "visually-h3",
    h4: "visually-h4",
    h5: "visually-h5",
    h6: "visually-h6",
    p: "visually-p",
    small: "visually-small",
    medium: "visually-medium",
    large: "visually-large",
    secondary: "visually-secondary-font",
    heading: "section-heading",
  };

  const Component = component ? component : "span";
  const variantClass = variant && variantMapping[variant];

  return createElement(
    Component,
    { className: classnames(styles[variantClass], className), dateTime },
    children
  );
};

export default Typography;
