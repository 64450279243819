export const netBase = {
  "--header-button-top-margin": "5px",

  "--color-button-text-primary": "#fff",
  "--color-button-background-secondary": "#fff",
  "--color-button-background-secondary-hover": "#ebeff1",
  "--color-button-text-secondary": "#000",
  "--color-dark-button-hover": "#fff",

  "--color-navbar-link-active": "#fff",
  "--color-navbar-link-mobile-active": "#fff",

  "--color-slide-index": "#fff",
  "--color-slide-caption": "#788993",

  "--color-podcast-tile-title": "#757575", //@todo: Update this color when designs are confirmed
};

export const sportBase = {
  "--header-button-top-margin": "10px",

  "--color-button-text-primary": "#000",
  "--color-button-background-secondary": "#222",
  "--color-button-background-secondary-hover": "#333",
  "--color-button-text-secondary": "#fff",
  "--color-dark-button-hover": "#000",

  "--color-navbar-link-active": "#e7ff00",
  "--color-navbar-link-mobile-active": "#000",

  "--color-slide-index": "#000",
  "--color-slide-caption": "#d2d2d2",

  "--color-podcast-tile-title": "#e7ff00",
};

export const netLayoutDiffs = {
  ...netBase,

  "--color-account-intro": "#fff",
  "--color-player-controls": "#fff",
  "--color-burger-links": "#fff",
  "--color-search-icon": "#1300e8",
  "--color-navigation-mobile": "#fff",
  "--color-featured-tile-background":
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 24.58%, #fff 82.2%)",
  "--error-size": "814px",
};

export const netAlternateLayoutDiffs = {
  ...netBase,

  "--color-account-intro": "#fff",
  "--color-player-controls": "#fff",
  "--color-burger-links": "#fff",
  "--color-search-icon": "#fff",
  "--color-navigation-mobile": "#fff",
  "--color-featured-tile-background":
    "linear-gradient(180deg, rgba(255, 255, 255, 0) 24.58%, #111316 82.2%)",
  "--error-size": "814px",
};

export const sportLayoutDiffs = {
  ...sportBase,

  "--color-account-intro": "#000",
  "--color-player-controls": "#000",
  "--color-burger-links": "#000",
  "--color-search-icon": "#e7ff00",
  "--color-navigation-mobile": "#000",
  "--color-featured-tile-background":
    "linear-gradient(180deg, rgba(21, 21, 21, 0) 24.58%, #151515 82.2%)",
  "--error-size": "467px",
};

export const sportAlternateLayoutDiffs = {
  ...sportBase,

  "--color-account-intro": "#000",
  "--color-player-controls": "#000",
  "--color-burger-links": "#000",
  "--color-search-icon": "#000",
  "--color-navigation-mobile": "#000",
  "--color-featured-tile-background":
    "linear-gradient(180deg, rgba(21, 21, 21, 0) 24.58%, var(--surface-secondary) 82.2%)",
  "--error-size": "467px",
};
