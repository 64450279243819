export function formatDuration(seconds: number): string {
  return new Date(seconds * 1000 || 0).toISOString().substr(11, 8);
}

export function getDescriptiveFormatDuration(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (hours > 0) return `${hours} godz. ${minutes - hours * 60} min`;

  return `${minutes} min`;
}

export function getReadingTime(seconds: number): string {
  const readingTime = Math.round(seconds / 60);

  if (readingTime > 4) {
    return `${readingTime} minut czytania`;
  } else if (readingTime > 1) {
    return `${readingTime} minuty czytania`;
  } else {
    return `1 minuta czytania`;
  }
}
