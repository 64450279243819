import React, { useLayoutEffect, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { useStoreState } from "pullstate";
import classnames from "classnames";

import {
  PlayerStore,
  psPauseAudio,
  psPlayAudio,
  psPlayPreviousPodcast,
  psTogglePlayerControls,
  radioSource,
} from "@/store/PlayerStore";
import PlayIcon from "@/icons/rounded-play-large.svg";
import PauseIcon from "@/icons/round-pause-large.svg";
import ArrowIcon from "@/icons/upArrowLarge.svg";
import { PlayerStatusTypes } from "@/helpers/pageHelpers/Common/interface";
import {
  getCurrentTrackSubtitle,
  getCurrentTrackTitle,
} from "@/helpers/getCurrentTrack";
import IconButton from "@/components/IconButton";
import Spinner from "@/components/Spinner";
import Artwork from "@/components/Players/Artwork";
import commonStyles from "@/components/Players/Common.module.scss";
import MinimalProgressBar from "@/components/Players/MinimalProgressBar";

import styles from "./BottomNavPlayer.module.scss";

export const BottomNavPlayer: React.FC = () => {
  const titleParentElement = useRef(null);
  const subtitleElement = useRef(null);

  const {
    radioSubTitle,
    showAdditionalControls,
    audioCurrentTime,
    audioDuration,
    subTitle: podcastSubTitle,
    isPlaying,
    isLoading,
    isAudioAvailable,
    isRadioSource,
    isPreviousPodcastAvailable,
    isEmptySource,
  } = useStoreState(PlayerStore, (store) => ({
    ...store,
    isPlaying: store.playerStatus === PlayerStatusTypes.Playing,
    isLoading: store.playerStatus === PlayerStatusTypes.Loading,
    isAudioAvailable: typeof window !== "undefined",
    isEmptySource: store.currentSource === "",
    isRadioSource: store.currentSource === radioSource,
    isPodcastSource:
      !!store.currentSource.length && store.currentSource !== radioSource,
    isPreviousPodcastAvailable: store.previousSource !== radioSource,
  }));

  const [titleWidth, setTitleWidth] = useState<number>(0);
  const [titleParentWidth, setTitleParentWidth] = useState<number>(0);

  // Apply marquee to subtitle
  useLayoutEffect(() => {
    setTitleWidth(subtitleElement.current.scrollWidth);
    setTitleParentWidth(titleParentElement.current.clientWidth);
  }, [radioSubTitle, podcastSubTitle, titleParentElement, subtitleElement]);

  // Controls
  const pauseAudio = () => {
    PlayerStore.update(psPauseAudio);
  };

  const playAudio = () => {
    PlayerStore.update(
      isPreviousPodcastAvailable ? psPlayPreviousPodcast : psPlayAudio
    );
  };

  const toggleMore = () => {
    PlayerStore.update(psTogglePlayerControls);
  };

  return (
    <div className={classnames(styles.wrapper)}>
      <div className={styles.playerBar}>
        <MinimalProgressBar
          currentTime={audioCurrentTime}
          duration={audioDuration}
          show={!isRadioSource && !isEmptySource}
          className={styles.timeline}
        />

        <div className={styles.imageWrapper}>
          <Artwork className={styles.artwork} />
        </div>

        {/* @todo: Refactor playerMeta with Marquee as one component with shared styles wrap around in div if needed */}
        <div className={styles.playerMeta}>
          <button
            ref={titleParentElement}
            onClick={toggleMore}
            className={styles.metaWrapper}
          >
            <p className={commonStyles.title}>{getCurrentTrackTitle()}</p>

            <p
              ref={subtitleElement}
              className={classnames(
                commonStyles.subTitle,
                titleWidth > titleParentWidth && commonStyles.marqueed
              )}
              style={{
                width:
                  titleWidth > titleParentWidth
                    ? titleParentWidth + "px"
                    : "auto",
              }}
            >
              {getCurrentTrackSubtitle()}
            </p>
          </button>

          <IconButton
            onClick={toggleMore}
            className={classnames(
              styles.toggler,
              showAdditionalControls && styles.active
            )}
          >
            <ArrowIcon />
          </IconButton>

          <div className={styles.controls}>
            {isAudioAvailable && !isLoading && (
              <IconButton
                className={styles.playButton}
                onClick={isPlaying ? pauseAudio : playAudio}
              >
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
              </IconButton>
            )}

            {isLoading && (
              <IconButton className={styles.spinnerButton}>
                <Spinner />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default dynamic(() => Promise.resolve(BottomNavPlayer), { ssr: false });
