import React from "react";
import classnames from "classnames";

import { MinimalProgressInterface } from "@/components/Players/MinimalProgressBar/MinimalProgressBar.interface";

import styles from "./MinimalProgressBar.module.scss";

export const MinimalProgressBar: React.FC<MinimalProgressInterface> = ({
  className,
  progressClassName,
  duration,
  currentTime,
  show,
  alignBottom,
}) => {
  const progressPercentage =
    parseFloat((currentTime / duration).toFixed(3)) * 100;

  return (
    <div
      className={classnames(
        styles.progressBar,
        show && styles.active,
        alignBottom && styles.alignBottom,
        className
      )}
    >
      <span
        style={{ width: progressPercentage + `%` }}
        className={classnames(styles.progress, progressClassName)}
      />
    </div>
  );
};

export default MinimalProgressBar;
