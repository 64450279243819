export const netArticles = {
  "--font-size-meta-info": "18px",
  "--font-size-meta-info-md": "22px",
  "--font-size-article-feed-tag": "14px",
  "--font-size-slide-index": "24px",
};

export const sportArticles = {
  "--font-size-meta-info": "22px",
  "--font-size-meta-info-md": "26px",
  "--font-size-article-feed-tag": "14px",
  "--font-size-slide-index": "24px",
};
