export const netNavigation = {
  "--font-family-navigation": '"HelveticaNowText-Bold", helvetica, sans-serif',
  "--font-family-dropdown": '"HelveticaNowText-Regular", helvetica, sans-serif',
  "--font-style-navigation": "normal",
  "--font-weight-navigation": "800",
  "--font-weight-dropdown": "400",
  "--font-size-dropdown": "14px",
  "--font-size-navigation": "14px",
  "--font-size-navigation-md": "14px",
  "--font-size-navigation-header": "24px",
  "--net-font-weight-navigation-desktop": "800", // net platform only
  "--color-mini-progress-bg": "#C0C7CC",
  "--color-mini-progress-bar": "#1300e8",
  "--active-position": "-13px",
};

export const sportNavigation = {
  "--font-family-navigation": "urw-din-condensed",
  "--font-family-dropdown": "urw-din-condensed",
  "--font-style-navigation": "italic",
  "--font-weight-navigation": "600",
  "--font-weight-dropdown": "600",
  "--font-size-dropdown": "16px",
  "--font-size-navigation": "14px",
  "--font-size-navigation-md": "14px",
  "--font-size-navigation-header": "28px",
  "--color-mini-progress-bg": "#7B7B7C",
  "--color-mini-progress-bar": "#e7ff00",
  "--active-position": "-10px",
};
