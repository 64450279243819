import React from "react";
import classNames from "classnames";
import Link from "next/link";
import { useStoreState } from "pullstate";
import Image from "next/image";

import {
  defaultRadioArtwork,
  PlayerStore,
  radioSource,
} from "shared/store/PlayerStore";
import { applicationRoutes } from "shared/helpers/applicationRouting";
import { PlayerStatusTypes } from "@/helpers/pageHelpers/Common/interface";
import { getImgixURL } from "@/helpers/getImgixURL";

import styles from "./Artwork.module.scss";
import ExternalArtwork from "@/components/Players/Artwork/ExternalArtwork";
import { getUrlToLoader } from "@/helpers/getUrlToLoader";

export const Artwork: React.FC<{
  className?: string;
  artworkSrc?: string;
  externalHref?: string;
  externalLinkAs?: string;
}> = ({ className, artworkSrc, externalHref, externalLinkAs }) => {
  const {
    currentSource,
    radioSubTitle,
    radioTitle,
    radioArtwork,
    scheduleArtworkUrl,
    slug,
    title: podcastTitle,
    subTitle: podcastSubTitle,
    artwork: podcastArtwork,
    playerStatus,
    isPreviousPodcastAvailable,
  } = useStoreState(PlayerStore, (store) => ({
    ...store,
    isPreviousPodcastAvailable: store.previousSource !== radioSource,
  }));
  const isRadioSource = currentSource === radioSource || currentSource === "";
  const imageAlt = isRadioSource
    ? `${radioTitle} - ${radioSubTitle}`
    : `${podcastTitle} - ${podcastSubTitle}`;
  const href = isRadioSource
    ? applicationRoutes.RADIO + "tracklista"
    : `${applicationRoutes.PODCAST_EPISODE}[slug]`;
  const linkAs = isRadioSource
    ? applicationRoutes.RADIO + "tracklista"
    : applicationRoutes.PODCAST_EPISODE + slug;

  const activeRadioArtwork =
    playerStatus === PlayerStatusTypes.Playing
      ? radioArtwork
      : scheduleArtworkUrl
        ? scheduleArtworkUrl
        : defaultRadioArtwork;

  const image = artworkSrc
    ? artworkSrc
    : isRadioSource && !isPreviousPodcastAvailable
      ? activeRadioArtwork
      : podcastArtwork;
  return (
    <Link href={externalHref || href} as={externalLinkAs || linkAs}>
      <div className={classNames(styles.image, className && className)}>
        {!image.includes("http") ? (
          <Image
            layout="fill"
            alt={imageAlt}
            objectFit="cover"
            src={getImgixURL(image)}
            loader={() => getUrlToLoader(image)}
          />
        ) : (
          <ExternalArtwork src={image} />
        )}
      </div>
    </Link>
  );
};

export default Artwork;
