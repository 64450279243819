import React from "react";
import styles from "./Artwork.module.scss";
import Image from "next/image";
import { getUrlToLoader } from "@/helpers/getUrlToLoader";

export const ExternalArtwork = ({ src }: { src: string }) => (
  <Image
    src={src}
    className={styles.image}
    loader={() => getUrlToLoader(src)}
    layout="fill"
  />
);

export default ExternalArtwork;
