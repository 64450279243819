import { FeaturedContentData } from "@/components/FeaturedSection/FeaturedSection.interface";

export enum FeedTypes {
  Article = "ARTICLE",
  Episode = "EPISODE",
  Ad = "ADVERTISEMENT",
  Video = "VIDEO",
}

export enum RecommendedTypes {
  Article = "ARTICLE",
  Video = "VIDEO",
  Episode = "EPISODE",
}

export enum PlayerStatusTypes {
  Loading = "loading",
  Playing = "playing",
  Paused = "paused",
}

export enum navigatorArtworkSize {
  Small = "small",
  Large = "large",
}

export type SlugType = string | string[];
export type ClassName = string;
export type Order = "asc" | "desc";

export interface OptionInterface {
  value: string;
  name: string;
}

export interface SortingInterface {
  field: string;
  direction: Order;
}

export type SlugInterface = {
  slug: SlugType;
};

export interface HomeInterface {
  meta: MetaInterface;
  seo?: MetaDataInterface;
  items?: FeedItemInterface[];
  cover?: FeaturedContentData;
}

export interface FeedInterface {
  items?: FeedItemInterface[];
}

interface FeedItemInterface {
  type: FeedTypes;
  object: any;
}

export interface QueryInterface {
  slug: SlugType;
  token?: string;
}

export interface AuthorBaseInterface {
  id: number;
  name: string;
  slug?: string;
  followed?: boolean;
}

export interface AuthorInterface extends AuthorBaseInterface {
  avatar?: {
    url?: string;
  };
  bio?: string;
}

export interface CommentsInputInterface {
  sendComment: (string) => void;
}

export interface CommentBoxInterface {
  message: string;
  displayName: string;
  createdAt: string;
  belongsToUser?: boolean;
  id: number;
  removeComment: (id: number) => void;
}

export enum CommentsType {
  Release = "RELEASE",
  Artist = "ARTIST",
  Article = "ARTICLE",
  Video = "VIDEO",
}

export interface CommentsInterface {
  slug: SlugType;
  type: CommentsType;
  contentData?: {
    title?: string;
    authors?: { name: string }[];
    tags?: TagInterface[];
  };
  subtags?: TagInterface[];
}

export interface MetaInterface {
  currentPage: number;
  perPage: number;
  totalPages?: number;
}

export enum PlacementTypes {
  Featured = "featured",
  Unfinished = "unfinished",
}

export interface MediaMetaData {
  slug: string;
  podcast: { slug: SlugType };
  title: string;
  subTitle: string;
  artwork: string;
  authors?: string[] | [string] | [];
  tags?: string[] | [string] | [];
  publishedAt?: string;
  placement?: PlacementTypes;
}

export interface PodcastEpisode {
  bookmarked: boolean;
  id: number;
  media: {
    artwork: string;
    duration: number;
    longVersionDuration: number;
    slug: string;
    subtitle: string;
    title: string;
    url: string;
  };
  picture: string;
  podcast: {
    title: string;
    slug: string;
    matureContent?: boolean;
  };
  publishedAt: string;
  serialNumber: number;
  slug: string;
  title: string;
  time?: number;
}

export interface PodcastEpisodePage extends PodcastEpisode {
  description: string;
  tracklist?: TrackInterface[];
}

export interface MetaHeadInterface {
  title: string;
  description: string;
}

export interface MetaDataInterface {
  title?: string;
  metaTitle?: string;
  description: string;
  metaDescription?: string;
  slug?: string;
  ogTitle?: string;
  ogFbTitle?: string;
  ogFbDescription?: string;
  ogFbImage?: string;
  ogTwTitle?: string;
  ogTwDescription?: string;
  ogTwImage?: string;
  canonical?: string;
}

export interface TrackInterface {
  author: string;
  title: string;
}

export interface RadioTrackInterface {
  title: string;
  artworkUrl: string;
  artworkUrlLarge: string;
  startTime: string;
}

export interface ScheduleItemInterface {
  endTime: string;
  startTime: string;
  podcast: {
    title: string;
    slug: string;
    artworkUrl: string;
    belongsToPortal: boolean;
  };
}

export interface CurrentScheduleInterface {
  data: ScheduleItemInterface;
}

export interface PodcastCardInterface {
  id: number;
  slug: string;
  title: string;
  artwork: string;
  hosts: AuthorInterface[];
  followed: boolean;
}

export interface PodcastTilesInterface {
  searchQuery?: string;
  items: PodcastCardInterface[];
}

export interface BookmarksWrapperInterface {
  items: any;
}

export interface TagInterface {
  id?: number;
  title: string;
  slug?: string;
}

export enum VideoPlayerVariant {
  Main = "MAIN",
  Feed = "FEED",
}

export interface VideoBase {
  title: string;
  uniqueId: string;
  slug: string;
  dateAdded: string;
  video: string;
  poster: string;
  bookmarked: boolean;
  subtags?: TagInterface[];
  authors?: AuthorInterface[];
}

export interface VideoPlayerInterface extends VideoBase {
  className?: ClassName;
  isFeatured?: boolean;
  isEmptyState?: boolean;
  variant?: VideoPlayerVariant;
  withLink?: boolean;
}

export interface VideoPageInterface extends VideoBase {
  className: ClassName;
}

export interface VolumeInterface {
  className: ClassName;
  onChange: (number) => void;
  onFinalChange: () => void;
}

export interface TimeInterface {
  currentTime: number;
  duration: number;
  onFinalChange: (number) => void;
}

export interface VideoPageInterface {
  initialData: VideoBase;
}

export interface VideoTileInterface extends VideoBase {
  slug: string;
  isFeatured?: boolean;
  isEmptyState?: boolean;
  title: string;
  publishedAt: string;
  url: string;
  picture: string;
  className: string;
}

export interface SearchInterface {
  phrase: string;
}

export interface RelatedContentInterface {
  keyword: string;
  className?: ClassName;
}

export enum PodcastType {
  Live = "live",
  Archived = "archived",
  Twitch = "twitch",
}
