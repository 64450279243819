import {
  defaultPageItems,
  emptySorting,
  ascSorting,
} from "shared/helpers/apiPathHelpers";

export const apiPath = {
  SEO: "portal_configuration",
  SESSION: "session",
  GOOGLE_SESSION: "google_authentication",
  FACEBOOK_SESSION: "facebook_authentication",
  APPLE_SESSION: "apple_authentication",
  EMAIL_CONFIRMATION: "email_confirmation",
  RESET_PASSWORD: "password_reset",
  USERS: "users",
  RECOMMENDED: "recommended_contents",
  SCHEDULES: "auditions_schedules",
  COVER_ARTICLES: "cover_articles",
  COVERABLE_CONTENTS: "coverable_contents",
  REDACTORS: "redactors",
  CURRENTLY_PLAYING_SCHEDULE: "current_schedule_item",
  DAILY_SCHEDULE: "daily_auditions_schedule",
  MAIN_TAGS: "main_tags",
  SUB_TAGS: "sub_tags",
  SPONSORED_TAGS: "sponsored_tags",
  POPULAR_TAGS: "popular_sub_tags",
  ME: "me/status",
  PROFILE: "me/profile",
  ONBOARDING: "onboarding",
  REMOVE_ACCOUNT: "account_deletion",
  STATIC_PAGES: "static_pages",
  RESOURCE_STATUSES: ({ page = 1, perPage = defaultPageItems.USER_CONTENT }) =>
  `me/resource_statuses?page=${page}&per_page=${perPage}`,
  ARTICLE_LIST: ({ perPage = defaultPageItems.MAIN_FEED }) =>
    `articles?per_page=${perPage}`,
  EPISODE_LIST: ({ perPage = defaultPageItems.MAIN_FEED }) =>
    `episodes?per_page=${perPage}`,
  FEED: ({ page = 1, perPage = defaultPageItems.MAIN_FEED, contentType= "feed" }) =>
    `${contentType}?page=${page}&per_page=${perPage}`,
  FEED_ARTICLES: ({ page = 1, perPage = defaultPageItems.SPECIFIC_FEED }) =>
    `articles?page=${page}&per_page=${perPage}`,
  FEED_PODCAST: ({ page = 1, perPage = defaultPageItems.SPECIFIC_FEED }) =>
    `episodes?page=${page}&per_page=${perPage}`,
  FEED_VIDEO: ({ page = 1, perPage = defaultPageItems.VIDEO_FEED }) =>
    `videos?page=${page}&per_page=${perPage}`,
  FEED_TAGS: ({ slug, page = 1, perPage = defaultPageItems.SPECIFIC_FEED }) =>
    `tags/${slug}/feed?page=${page}&per_page=${perPage}`,
  PERSONAL_FEED: ({ page = 1, perPage = defaultPageItems.PERSONAL_FEED }) =>
    `me/feed?page=${page}&per_page=${perPage}`,
  PERSONAL_FEED_ARTICLES: ({
    page = 1,
    perPage = defaultPageItems.SPECIFIC_FEED,
  }) => `me/feed/articles?page=${page}&per_page=${perPage}`,
  PERSONAL_FEED_PODCAST: ({
    page = 1,
    perPage = defaultPageItems.SPECIFIC_FEED,
  }) => `me/feed/episodes?page=${page}&per_page=${perPage}`,
  PERSONAL_FEED_VIDEO: ({ page = 1, perPage = defaultPageItems.VIDEO_FEED }) =>
    `me/feed/videos?page=${page}&per_page=${perPage}`,
  BOOKMARKS: ({
    page = 1,
    perPage = defaultPageItems.MAIN_FEED,
    searchQuery = "",
    sort = ascSorting,
  }) =>
    `me/bookmarks?page=${page}&per_page=${perPage}&search_query=${searchQuery}&direction=${sort.direction}`,
  EPISODES_BOOKMARKS: ({
    page = 1,
    perPage = defaultPageItems.MAIN_FEED,
    searchQuery = "",
    sort = ascSorting,
  }) =>
    `me/bookmarks/episodes?page=${page}&per_page=${perPage}&search_query=${searchQuery}&direction=${sort.direction}`,
  PODCASTS: ({
    page = 1,
    perPage = defaultPageItems.PODCASTS,
    searchQuery = "",
    sort = { field: "published_at", direction: "desc" },
    filter = "",
  }) =>
    `podcasts?page=${page}&per_page=${perPage}&search_query=${searchQuery}&field=${sort.field}&direction=${sort.direction}&filter=${filter}`,
  PODCAST: (slug) => `podcasts/${slug}`,
  SIMILAR_PODCASTS: (slug) => `podcasts/${slug}/similar`,
  EPISODES: ({
    slug,
    page = 1,
    perPage = defaultPageItems.PODCASTS,
    searchQuery = "",
    sort = { field: "published_at", direction: "desc" },
    filter = "",
  }) =>
    `podcasts/${slug}/episodes?page=${page}&per_page=${perPage}&search_query=${searchQuery}&field=${sort.field}&direction=${sort.direction}&filter=${filter}`,
  PODCAST_EPISODE: (slug) => `episodes/${slug}`,
  MORE_EPISODES: ({ slug, page = 1, perPage = defaultPageItems.PODCASTS }) =>
    `episodes/${slug}/more_episodes?page=${page}&per_page=${perPage}`,
  ARTICLES: (slug) => `articles/${slug}`,
  VIDEO: (slug) => `videos/${slug}`,
  VIDEO_URL: (slug) => `videos/${slug}/content_url`,
  POPULAR_ARTICLES: (slug) => `popular_articles/?current_article_slug=${slug}`,
  RELATED_ARTICLES: (slug) => `articles/${slug}/related`,
  TAG: (slug) => `tags/${slug}`,
  REDACTOR_DETAILS: (slug) => `redactors/${slug}`,
  REDACTOR_FEED: ({
    slug,
    page = 1,
    perPage = defaultPageItems.SPECIFIC_FEED,
  }) => `redactors/${slug}/feed?page=${page}&per_page=${perPage}`,
  CONTENT_SUBTAGS: (slug, type) => `${type}/${slug}/sub_tags`,
  USER_SUBTAGS: "me/sub_tags",
  REMOVE_USER_SUBTAGS: (slug) => `me/sub_tags/${slug}`,
  FOLLOW_AUTHOR: "me/redactors",
  REMOVE_FOLLOW_AUTHOR: (slug) => `me/redactors/${slug}`,
  FOLLOW_PODCAST: "me/podcasts",
  REMOVE_FOLLOW_PODCAST: (slug) => `me/podcasts/${slug}`,
  COLOR_SETTINGS: "me/color_settings",
  RELATED_TAGS: "me/related_tags",
  FOLLOWED_SUBTAGS: ({
    page = 1,
    perPage = defaultPageItems.FOLLOWED_SUBTAGS,
  }) => `me/sub_tags?page=${page}&per_page=${perPage}`,
  FOLLOWED_AUTHORS: ({
    page = 1,
    perPage = defaultPageItems.FOLLOWED_AUTHORS,
  }) => `me/redactors?page=${page}&per_page=${perPage}`,
  FOLLOWED_PODCASTS: ({
    page = 1,
    perPage = defaultPageItems.FOLLOWED_PODCASTS,
    searchQuery = "",
    sort = { field: "title", direction: "asc" },
  }) =>
    `me/podcasts?page=${page}&per_page=${perPage}&search_query=${searchQuery}&field=${sort.field}&direction=${sort.direction}`,
  SEARCH_ARTICLES: ({
    page = 1,
    perPage = defaultPageItems.SEARCH_DEFAULT,
    searchQuery = "",
  }) =>
    `search/articles?page=${page}&per_page=${perPage}&search_query=${searchQuery}`,
  SEARCH_PODCASTS: ({
    page = 1,
    perPage = defaultPageItems.SEARCH_DEFAULT,
    searchQuery = "",
  }) => `search/podcasts?search_query=${searchQuery}`,
  SEARCH_AUTHORS: ({
    page = 1,
    perPage = defaultPageItems.SEARCH_DEFAULT,
    searchQuery = "",
  }) => `search/redactors?search_query=${searchQuery}`,
  SEARCH_EPISODES: ({
    page = 1,
    perPage = defaultPageItems.SEARCH_DEFAULT,
    searchQuery = "",
  }) =>
    `search/episodes?page=${page}&per_page=${perPage}&search_query=${searchQuery}`,
  SEARCH_VIDEOS: ({
    page = 1,
    perPage = defaultPageItems.SEARCH_DEFAULT,
    searchQuery = "",
  }) =>
    `search/videos?page=${page}&per_page=${perPage}&search_query=${searchQuery}`,
  SEARCH_ARTISTS: ({
    page = 1,
    perPage = defaultPageItems.SEARCH_DEFAULT,
    searchQuery = "",
  }) =>
    `search/artists?page=${page}&per_page=${perPage}&search_query=${searchQuery}`,
  SEARCH_RELEASES: ({
    page = 1,
    perPage = defaultPageItems.SEARCH_DEFAULT,
    searchQuery = "",
  }) =>
    `search/releases?page=${page}&per_page=${perPage}&search_query=${searchQuery}`,
  SEARCH_ALL: ({ searchQuery = "" }) =>
    `search/content_search?search_query=${searchQuery}`,
  SEARCH_RELATED_ARTICLES: ({
    page = 1,
    perPage = defaultPageItems.RELATED_CONTENT,
    searchQuery = "",
  }) =>
    `search/related_contents/articles?page=${page}&per_page=${perPage}&search_query=${searchQuery}`,
  SEARCH_RELATED_EPISODES: ({
    page = 1,
    perPage = defaultPageItems.RELATED_CONTENT,
    searchQuery = "",
  }) =>
    `search/related_contents/episodes?page=${page}&per_page=${perPage}&search_query=${searchQuery}`,
  BOOKMARK_ARTICLES: "me/bookmarks/articles",
  REMOVE_BOOKMARK_ARTICLES: (slug) => `me/bookmarks/articles/${slug}`,
  BOOKMARK_EPISODES: "me/bookmarks/episodes",
  REMOVE_BOOKMARK_EPISODES: (slug) => `me/bookmarks/episodes/${slug}`,
  BOOKMARK_VIDEOS: "me/bookmarks/videos",
  REMOVE_BOOKMARK_VIDEOS: (slug) => `me/bookmarks/videos/${slug}`,
  PAGE: (slug) => `static_pages/${slug}`,
  ARTISTS: ({
    page = 1,
    perPage = defaultPageItems.MUSIC_DEFAULT,
    searchQuery = "",
  }) =>
    `music/artists?page=${page}&per_page=${perPage}&search_query=${searchQuery}`,
  ARTIST: (slug) => `music/artists/${slug}`,
  ARTIST_RELEASES: (slug) => `music/artists/${slug}/releases`,
  POPULAR_ARTISTS: "music/popular_artists",
  RECOMMENDED_RELEASES: "music/recommended_releases",
  MOST_RATED_RELEASES: "music/most_rated_releases",
  NEW_RELEASES: "music/new_releases",
  RELEASE: (slug) => `music/releases/${slug}`,
  RATE_RELEASE: (slug) => `music/releases/${slug}/user_rating`,
  RELEASES: ({
    page = 1,
    perPage = defaultPageItems.MUSIC_DEFAULT,
    searchQuery = "",
  }) =>
    `music/releases?page=${page}&per_page=${perPage}&search_query=${searchQuery}`,
  RELEASE_COMMENTS: ({ slug, page = 1, perPage = defaultPageItems.COMMENTS }) =>
    `music/releases/${slug}/comments?page=${page}&per_page=${perPage}`,
  POST_RELEASE_COMMENT: ({ slug }) => `music/releases/${slug}/comments`,
  REMOVE_RELEASE_COMMENT: ({ slug, id }) =>
    `music/releases/${slug}/comments/${id}`,
  ARTIST_COMMENTS: ({ slug, page = 1, perPage = defaultPageItems.COMMENTS }) =>
    `music/artists/${slug}/comments?page=${page}&per_page=${perPage}`,
  POST_ARTIST_COMMENT: ({ slug }) => `music/artists/${slug}/comments`,
  REMOVE_ARTIST_COMMENT: ({ slug, id }) =>
    `music/artists/${slug}/comments/${id}`,
  RADIO_AD: "radio_creation",
  RATED_RELEASES: ({
    page = 1,
    perPage = defaultPageItems.MAIN_FEED,
    sort = { field: "created_at", direction: "desc" },
  }) =>
    `me/rated_releases?page=${page}&per_page=${perPage}&field=${sort.field}&direction=${sort.direction}`,
  LISTENED_POSITION: (slug) => `episodes/${slug}/status`,
  READ_ARTICLES_POSITION: (slug) => `articles/${slug}/status`,
  USER_NEWSLETTER: "mailer_users",
  LISTENED_EPISODES: "me/resource_statuses_slugs?filter=episode",
  ARTICLE_COMMENTS: ({ slug, page = 1, perPage = defaultPageItems.COMMENTS }) =>
    `articles/${slug}/comments?page=${page}&per_page=${perPage}`,
  POST_ARTICLE_COMMENT: ({ slug }) => `articles/${slug}/comments`,
  REMOVE_ARTICLE_COMMENT: ({ slug, id }) => `articles/${slug}/comments/${id}`,
  VIDEO_COMMENTS: ({ slug, page = 1, perPage = defaultPageItems.COMMENTS }) =>
    `videos/${slug}/comments?page=${page}&per_page=${perPage}`,
  POST_VIDEO_COMMENT: ({ slug }) => `videos/${slug}/comments`,
  REMOVE_VIDEO_COMMENT: ({ slug, id }) => `videos/${slug}/comments/${id}`,
  SLUGS: ({ perPage = 50, resource }) =>
    `slugs/${resource}?per_page=${perPage}`,
};

export const oneSignalAPI = (id) =>
  `https://onesignal.com/api/v1/players/${id}?app_id=${process.env.NEXT_PUBLIC_ONE_SIGNAL_APP_ID}`;
