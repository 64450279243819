import React, { useEffect } from "react";
import { getTrackBackground, Range } from "react-range";
import { useStoreState } from "pullstate";
import classnames from "classnames";

import { AudioTimelineInterface } from "@/components/Players/AudioTimeline/AudioTimeline.interface";
import {
  PlayerStore,
  psChangeTime,
  psPlayAudio,
  psPlayPreviousPodcast,
  radioSource,
} from "@/store/PlayerStore";
import { formatDuration } from "@/helpers/formatDuration";
import styles from "@/components/Players/Common.module.scss";
import { UIStore } from "@/store/UIStore";
import { setPosition } from "@/helpers/setPosition";
import { getListenedEpisodes } from "@/helpers/getListenedEpisodes";

import { PlayerTabTypes } from "../DesktopPlayer/DesktopPlayer.interface";

export const AudioTimeline: React.FC<AudioTimelineInterface> = ({
  className,
  withElapsedTime,
  variant,
  podcastDuration,
  podcastTime,
  podcastSlug
}) => {
  const { userLoggedIn } = useStoreState(UIStore, (store) => store);
  const {
    audioCurrentTime: playerCurrentTime,
    audioDuration: playerAudioDuration,
    slug: playerSlug,
    activePlayerTab,
    isRadioSource
  } = useStoreState(PlayerStore, (store) => ({
    slug: store.slug,
    audioCurrentTime: store.audioCurrentTime,
    audioDuration:
      store.currentSource === radioSource ? 1 : Math.round(store.audioDuration),
    activePlayerTab: store.activePlayerTab,
    isRadioSource: store.currentSource === radioSource,
  }));
  const progressBarColor = "var(--color-negative)";
  const slug = podcastSlug as string || playerSlug;
  const currentTime = podcastTime || playerCurrentTime;
  const audioDuration = podcastDuration || playerAudioDuration; 

  const setUserPosition = async (time: number) => {
    if (userLoggedIn) {
      setPosition(slug, time);
    }
  };

  const changeTime = async (value) => {
    const time = value[0] > 0 ? value[0] : 1;
    isRadioSource && PlayerStore.update(psPlayPreviousPodcast);
    PlayerStore.update(psChangeTime(time));
    await setUserPosition(time);
    await getListenedEpisodes();
  };

  const finalChange = () => {
    PlayerStore.update(psPlayAudio);
  };

  useEffect(() => {
    const updatePosition = async (position: number) => {
      await setUserPosition(position);
    };

    if (currentTime % 20 === 0 && currentTime > 0) {
      updatePosition(currentTime);
    }
  }, [currentTime]);

  return (
    <div className={classnames(styles.timeline, styles[variant], className)}>
      <div
        className={classnames(
          styles.mixerWrapper,
          styles[variant],
          withElapsedTime && styles.pushToBottom
        )}
      >
        <Range
          values={[
            currentTime > audioDuration ? 1 : currentTime < 0 ? 0 : currentTime,
          ]}
          step={1}
          min={0}
          max={audioDuration === 0 ? 1 : audioDuration}
          onFinalChange={finalChange}
          onChange={changeTime}
          renderTrack={({ props, children }) => (
            <div
              className={classnames(
                styles.mixerTrack,
                styles[variant],
                withElapsedTime && styles.pushToBottom
              )}
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{ ...props.style }}
            >
              <div
                className={classnames(styles.mixerProgress, styles[variant])}
                ref={props.ref}
                style={{
                  background: getTrackBackground({
                    values: [
                      activePlayerTab === PlayerTabTypes.Radio ||
                      currentTime > audioDuration
                        ? 1
                        : currentTime,
                    ],
                    colors: [progressBarColor, "transparent"],
                    min: 0,
                    max: audioDuration === 0 ? 1 : audioDuration,
                  }),
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              style={{ ...props.style }}
              className={classnames(styles.mixerThumb, styles[variant])}
              {...props}
            >
              <div className={classnames(styles.mixerTooltip)}>
                {formatDuration(currentTime)}
              </div>
            </div>
          )}
        />
      </div>
      {withElapsedTime && [
        <div
          key="elapsed"
          className={classnames(styles.elapsed, styles[variant])}
        >
          {formatDuration(currentTime)}
        </div>,
        <div
          key="duration"
          className={classnames(styles.duration, styles[variant])}
        >
          -{formatDuration(audioDuration - currentTime)}
        </div>,
      ]}
    </div>
  );
};

export default AudioTimeline;
