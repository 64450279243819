export const applicationRouteNamespace = {
  ARTICLE: "artykul",
  AUTHOR: "redaktor",
  PODCAST: "podcast",
  VIDEO: "wideo",
  PODCAST_EPISODE: "epizod",
  RADIO: "radio",
  TAG: "tag",
  BOOKMARKED: "zapisane",
};

export const applicationRoutes = {
  FEED: "/",
  ARTICLE: `/${applicationRouteNamespace.ARTICLE}/`,
  AUTHOR: `/${applicationRouteNamespace.AUTHOR}/`,
  PODCAST: `/${applicationRouteNamespace.PODCAST}/`,
  VIDEO: `/${applicationRouteNamespace.VIDEO}/`,
  PODCAST_EPISODE: `/${applicationRouteNamespace.PODCAST_EPISODE}/`,
  RADIO: `/${applicationRouteNamespace.RADIO}/`,
  TAG: `/${applicationRouteNamespace.TAG}/`,
  BOOKMARKED: `/konto/${applicationRouteNamespace.BOOKMARKED}/`,
  SEARCH: `/szukajka`,
  ARTIST: `/baza-muzyczna/artysta/`,
  RELEASE: `/baza-muzyczna/wydawnictwo/`,
};
