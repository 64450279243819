import { useStoreState } from "pullstate";

import { PlayerStore, radioSource } from "shared/store/PlayerStore";
import { PlayerStatusTypes } from "@/helpers/pageHelpers/Common/interface";

export function getRadioTrackTitle(): string {
  const radioTitle = useStoreState(PlayerStore, (store) => store.radioTitle);
  const playerStatus = useStoreState(
    PlayerStore,
    (store) => store.playerStatus
  );
  const scheduleTitle = useStoreState(
    PlayerStore,
    (store) => store.scheduleTitle
  );
  const currentSource = useStoreState(
    PlayerStore,
    (store) => store.currentSource
  );

  const activeRadioTitle =
    playerStatus === PlayerStatusTypes.Playing && currentSource === radioSource
      ? radioTitle
      : scheduleTitle
      ? scheduleTitle
      : "newonce.radio";

  return activeRadioTitle;
}

export function getRadioTrackSubtitle(): string {
  const playerStatus = useStoreState(
    PlayerStore,
    (store) => store.playerStatus
  );
  const radioSubTitle = useStoreState(
    PlayerStore,
    (store) => store.radioSubTitle
  );
  const currentSource = useStoreState(
    PlayerStore,
    (store) => store.currentSource
  );

  const activeRadioSubTitle =
    playerStatus === PlayerStatusTypes.Playing && currentSource === radioSource
      ? radioSubTitle
      : "Wciśnij play";

  return activeRadioSubTitle;
}

export function getCurrentTrackTitle(): string {
  const currentSource = useStoreState(
    PlayerStore,
    (store) => store.currentSource
  );

  const previousSource = useStoreState(
    PlayerStore,
    (store) => store.previousSource
  );
  const podcastTitle = useStoreState(PlayerStore, (store) => store.title);
  const isRadio = currentSource === radioSource || currentSource === "";
  const isPreviousPodcast =
    previousSource !== radioSource && currentSource === "";
  const activeRadioTrackTitle = getRadioTrackTitle();

  if (isPreviousPodcast) {
    return podcastTitle;
  }

  return isRadio ? activeRadioTrackTitle : podcastTitle;
}

export function getCurrentTrackSubtitle(): string {
  const currentSource = useStoreState(
    PlayerStore,
    (store) => store.currentSource
  );
  const previousSource = useStoreState(
    PlayerStore,
    (store) => store.previousSource
  );
  const podcastSubTitle = useStoreState(PlayerStore, (store) => store.subTitle);
  const isRadio = currentSource === radioSource || currentSource === "";
  const isPreviousPodcast =
    previousSource !== radioSource && currentSource === "";
  const activeRadioTrackSubtitle = getRadioTrackSubtitle();

  if (isPreviousPodcast) {
    return podcastSubTitle;
  }

  return isRadio ? activeRadioTrackSubtitle : podcastSubTitle;
}
