import { useLayoutEffect, useRef, useState } from "react";
import { useStoreState } from "pullstate";
import classnames from "classnames";
import Link from "next/link";

import {
  defaultRadioArtwork,
  PlayerStore,
  psPauseAudio,
  psPlayAudio,
  psPlayPreviousRadio,
  psPlaySource,
  radioSource,
} from "@/store/PlayerStore";
import Artwork from "@/components/Players/Artwork";
import AudioTimeline from "@/components/Players/AudioTimeline";
import IconButton from "@/components/IconButton";
import Spinner from "@/components/Spinner";
import PauseIcon from "@/icons/rounded-pause-medium.svg";
import PlayIcon from "@/icons/rounded-play-medium.svg";
import { applicationRoutes } from "@/helpers/applicationRouting";
import { PlayerStatusTypes } from "@/helpers/pageHelpers/Common/interface";
import {
  getRadioTrackSubtitle,
  getRadioTrackTitle,
} from "@/helpers/getCurrentTrack";

import styles from "../DesktopPlayer.module.scss";

const RadioPlayer = () => {
  const metaParentElement = useRef(null);
  const titleElement = useRef(null);
  const subtitleElement = useRef(null);
  const [metaParentWidth, setMetaParentWidth] = useState<number>(0);
  const [titleWidth, setTitleWidth] = useState<number>(0);
  const [subTitleWidth, setSubTitleWidth] = useState<number>(0);

  const {
    isPlaying,
    isLoading,
    currentSource,
    radioTitle,
    radioSubTitle,
    radioArtworkLarge,
    radioArtwork,
    isCurrentSource,
    isPreviousPodcastAvailable,
    scheduleArtworkUrl,
  } = useStoreState(PlayerStore, (store) => ({
    ...store,
    isPlaying: store.playerStatus === PlayerStatusTypes.Playing,
    isLoading: store.playerStatus === PlayerStatusTypes.Loading,
    isCurrentSource: !!store.currentSource.length,
    isPreviousPodcastAvailable: store.previousSource !== radioSource,
  }));
  const isRadioSource = currentSource === radioSource;
  const activeRadioTitle = getRadioTrackTitle();
  const activeRadioSubTitle = getRadioTrackSubtitle();
  const activeRadioArtwork =
    isPlaying && isRadioSource
      ? radioArtwork
      : scheduleArtworkUrl
      ? scheduleArtworkUrl
      : defaultRadioArtwork;

  const pauseAudio = () => {
    PlayerStore.update(psPauseAudio);
  };

  const playAudio = () => {
    if (isRadioSource) {
      PlayerStore.update(psPlayAudio);
    } else {
      if (
        isPlaying ||
        (isCurrentSource && isPreviousPodcastAvailable) ||
        isCurrentSource
      ) {
        PlayerStore.update(psPlayPreviousRadio);
      } else {
        PlayerStore.update(
          psPlaySource(radioSource, {
            title: radioTitle,
            subTitle: radioSubTitle,
            artwork: radioArtworkLarge,
            slug: undefined,
            podcast: undefined,
          })
        );
      }
    }
  };

  useLayoutEffect(() => {
    setMetaParentWidth(metaParentElement.current.clientWidth);
    setTitleWidth(titleElement.current.scrollWidth);
    setSubTitleWidth(subtitleElement.current.scrollWidth);
  }, [
    activeRadioSubTitle,
    activeRadioTitle,
    subtitleElement,
    titleElement,
    metaParentElement,
  ]);

  return (
    <>
      <Artwork
        className={styles.artwork}
        artworkSrc={activeRadioArtwork}
        externalHref={applicationRoutes.RADIO + "tracklista"}
        externalLinkAs={applicationRoutes.RADIO + "tracklista"}
      />
      <Link
        href={applicationRoutes.RADIO + "tracklista"}
        as={applicationRoutes.RADIO + "tracklista"}
      >
        <a className={styles.link}>
          <div className={styles.meta} ref={metaParentElement}>
            <p
              ref={titleElement}
              className={classnames(
                styles.bold,
                styles.title,
                titleWidth > metaParentWidth && styles.marqueed
              )}
            >
              {activeRadioTitle}
            </p>
            <p
              ref={subtitleElement}
              className={classnames(
                styles.title,
                subTitleWidth > metaParentWidth && styles.marqueed
              )}
            >
              {activeRadioSubTitle}
            </p>
          </div>
        </a>
      </Link>
      <div key="radio" className={classnames(styles.timelineContainer, styles.radioTimeline)}>
        <div className={styles.controls}>
          <IconButton
            variant="round"
            size="medium"
            className={styles.playButton}
            onClick={isPlaying && isRadioSource ? pauseAudio : playAudio}
          >
            {isLoading ? (
              <Spinner className={styles.spinner} />
            ) : isPlaying && isRadioSource ? (
              <PauseIcon />
            ) : (
              <PlayIcon />
            )}
          </IconButton>
        </div>

        <AudioTimeline className={styles.timeline} variant="radio" />
      </div>
    </>
  );
};

export default RadioPlayer;
