import { useEffect, useState } from "react";

export const useDetectAdBlock = () => {
  const [adBlockDetected, setAdBlockDetected] = useState<boolean>(false);

  useEffect(() => {
    const url = "https://www3.doubleclick.net";
    fetch(url, {
      method: "HEAD",
      mode: "no-cors",
      cache: "no-store",
    }).catch(() => {
      setAdBlockDetected(true);
    });
  }, []);

  return adBlockDetected;
};
