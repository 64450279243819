import React, { MouseEvent } from "react";
import Link from "next/link";
import classnames from "classnames";

import { authPaths } from "@/helpers/routePath";
import { UIStore } from "@/store/UIStore";

import styles from "../BottomNavigation.module.scss";

import { BottomNavigationActionProps } from "./BottomNavigationActionProps.interface";

export const BottomNavigationAction: React.FC<BottomNavigationActionProps> = ({
  href,
  icon: Icon,
  label,
  isActive,
}) => {
  const openModal = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    UIStore.update((store) => {
      store.authModalOpen = true;
    });
  };
  return (
    <Link href={href}>
      <a
        className={classnames(styles.link, { [styles.active]: isActive })}
        title={label}
        onClick={authPaths.includes(href) && openModal}
      >
        <Icon />
        <span className={styles.label}>{label}</span>
      </a>
    </Link>
  );
};

export default BottomNavigationAction;
