import React, { useEffect } from "react";
import localforage from "localforage";
import { useStoreState } from "pullstate";

import { UIStore } from "@/store/UIStore";
import { theme } from "@/styles/theme";
import { ComponentProps } from "@/helpers/componentProps";

import styles from "./ThemeWrapper.module.scss";

const portal = process.env.NEXT_PUBLIC_PORTAL;

export const ThemeProvider: React.FC<ComponentProps> = ({ children }) => {
  const { alternateColors, userLoggedIn } = useStoreState(
    UIStore,
    (store) => store
  );
  const activeTheme = alternateColors
    ? theme[`${portal}Alternate`]
    : theme[portal];

  useEffect(() => {
    if (typeof window !== "undefined") {
      localforage
        .getItem("alternateColors")
        .then((response: boolean) => {
          UIStore.update((store) => {
            store.alternateColors = response;
          });

          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                event: "toggleDarkMode",
                isDarkMode: response,
              })
            );
          }
        })
        .catch(() => {
          return null;
        });
    }
  }, []);

  return (
    <div
      className={styles.wrapper}
      style={userLoggedIn ? activeTheme : theme[portal]}
    >
      {children}
    </div>
  );
};

export default ThemeProvider;
