const sharedFooterVariables = {
  "--footer-background": "#000",
  "--footer-color": "#fff",
  "--footer-link-color": "#788993",
  "--footer-copyright-color": "#666",
};

export const footerVariablesSport = {
  ...sharedFooterVariables,
  "--footer-social-color": "#000",
};

export const footerVariablesNet = {
  ...sharedFooterVariables,
  "--footer-social-color": "#fff",
};

export const footerVariablesSportAlternate = {
  ...sharedFooterVariables,
  "--footer-social-color": "#000",
};

export const footerVariablesNetAlternate = {
  ...sharedFooterVariables,
  "--footer-social-color": "#fff",
};
