import { forwardRef } from "react";
import classnames from "classnames";
import { useStoreState } from "pullstate";

import { UIStore } from "@/store/UIStore";

import { IconButtonProps } from "./IconButton.interface";
import styles from "./IconButton.module.scss";

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    { variant, children, onClick, className, size, active = false, disabled },
    ref
  ) => {
    const { alternateColors } = useStoreState(UIStore, (store) => store);
    const isSport = process.env.NEXT_PUBLIC_PORTAL === "sport";
    const isSportLightMode = alternateColors && isSport;

    return (
      <button
        className={classnames(
          styles.iconButton,
          variant && styles[variant],
          size && styles[size],
          active && styles.active,
          isSportLightMode && styles.lightSport,
          className
        )}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

export default IconButton;
