import { CSSProperties } from "react";

import {
  sportLightColors,
  sportDarkColors,
  netLightColors,
  netDarkColors,
} from "@/styles/themes/colors";
import {
  netFontFamily,
  netTypography,
  sportFontFamily,
  sportTypography,
  netButtonsTypography,
  sportButtonsTypography,
} from "@/styles/themes/fonts";
import { netNavigation, sportNavigation } from "@/styles/themes/navigation";
import {
  footerVariablesSport,
  footerVariablesSportAlternate,
  footerVariablesNet,
  footerVariablesNetAlternate,
} from "@/styles/themes/footer";
import { netArticles, sportArticles } from "@/styles/themes/articles";
import {
  netLayoutDiffs,
  netAlternateLayoutDiffs,
  sportLayoutDiffs,
  sportAlternateLayoutDiffs,
} from "@/styles/themes/layoutDiff";

export const theme = {
  sport: {
    // Colors
    ...sportDarkColors,

    // Font Families
    ...sportFontFamily,

    // Typography
    ...sportTypography,

    // Buttons
    ...sportButtonsTypography,

    // Navigation
    ...sportNavigation,

    // Footer
    ...footerVariablesSport,

    // Article Related Stuff
    ...sportArticles,

    // Layout Differences
    ...sportLayoutDiffs,
  },
  sportAlternate: {
    // Colors
    ...sportLightColors,

    // Font Families
    ...sportFontFamily,

    // Typography
    ...sportTypography,

    // Buttons
    ...sportButtonsTypography,

    // Navigation
    ...sportNavigation,

    // Footer
    ...footerVariablesSportAlternate,

    // Article Related Stuff
    ...sportArticles,

    // Layout Differences
    ...sportAlternateLayoutDiffs,
  },
  net: {
    // Colors
    ...netLightColors,

    // Font Families
    ...netFontFamily,

    // Typography
    ...netTypography,

    // Buttons
    ...netButtonsTypography,

    // Navigation
    ...netNavigation,

    // Footer
    ...footerVariablesNet,

    // Article Related Stuff
    ...netArticles,

    // Layout Differences
    ...netLayoutDiffs,
  },
  netAlternate: {
    // Colors
    ...netDarkColors,

    // Font Families
    ...netFontFamily,

    // Typography
    ...netTypography,

    // Buttons
    ...netButtonsTypography,

    // Navigation
    ...netNavigation,

    // Footer
    ...footerVariablesNetAlternate,

    // Article Related Stuff
    ...netArticles,

    // Layout Differences
    ...netAlternateLayoutDiffs,
  },
} as CSSProperties;
