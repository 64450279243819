import { registerInDevtools, Store } from "pullstate";

import { PlayerStoreInterface } from "@/helpers/pageHelpers/Store/interface";
import {
  MediaMetaData,
  PlayerStatusTypes,
} from "@/helpers/pageHelpers/Common/interface";
import { setPosition } from "@/helpers/setPosition";
import { PlayerTabTypes } from "@/components/Players/DesktopPlayer/DesktopPlayer.interface";

export const radioSource = process.env.NEXT_PUBLIC_RADIO_SOURCE;
export const SBMFFestivalSource =
  process.env.NEXT_PUBLIC_RADIO_SBM_FFESTIVAL_SOURCE;
export const defaultRadioArtwork = `https://cdn.newonce.me/uploads/images/46962/Placeholder_Image.png`;

export const psPlaySource =
  (source: string, metaData: MediaMetaData, time?: number) =>
  (store: PlayerStoreInterface) => {
    if (store.currentSource !== source) {
      store.currentSource = source;
      store.audioCurrentTime = time ? time : 0;
      store.slug = metaData.slug;
      store.podcast.slug = metaData?.podcast?.slug;
      store.title = metaData.title;
      store.subTitle = metaData.subTitle;
      store.artwork = metaData.artwork;
      store.authors = metaData?.authors || [];
      store.tags = metaData?.tags || [];
      store.publishedAt = metaData?.publishedAt;
      store.placement = metaData?.placement;
    }
    psPlayAudio(store);
  };

export const psPauseAudio = (store: PlayerStoreInterface) => {
  const isRadioSource = store.currentSource === radioSource;
  if (isRadioSource) {
    store.audioCurrentTime = 1;
  }
  store.playerStatus = PlayerStatusTypes.Paused;

  if (store?.slug && store?.listenedEpisodes !== null) {
    if (store.listenedEpisodes && store.listenedEpisodes[store.slug]) {
      store.listenedEpisodes[store.slug].status = store.audioCurrentTime;
    } else if (store.listenedEpisodes) {
      store.listenedEpisodes[store.slug] = {
        status: store.audioCurrentTime,
      };
    }
    setPosition(store.slug, store.audioCurrentTime);
  }
};

export const psTogglePlayerControls = (store: PlayerStoreInterface) => {
  store.showAdditionalControls = !store.showAdditionalControls;
};

export const psChangeTime =
  (value: number) => (store: PlayerStoreInterface) => {
    const isRadioSource = store.currentSource === radioSource;
    if (isRadioSource) return;
    store.playerStatus = PlayerStatusTypes.Loading;
    store.audioCurrentTime = value;
  };

export const psPlayAudio = (store: PlayerStoreInterface) => {
  const isRadioUnmounted = store.currentSource === "";
  if (isRadioUnmounted) {
    store.currentSource = radioSource;
    store.audioCurrentTime = 1;
  }
  if (store.currentSource === radioSource) {
    store.activePlayerTab = PlayerTabTypes.Radio;
  } else {
    store.activePlayerTab = PlayerTabTypes.Podcast;
  }
  if (store.playerStatus === PlayerStatusTypes.Paused) {
    store.playerStatus = PlayerStatusTypes.Loading;
  }
};

export const psChangeUiVolume =
  (value: [number]) => (store: PlayerStoreInterface) => {
    store.uiVolume = value;
  };

export const psChangeVolume =
  (value: number | number[]) => (store: PlayerStoreInterface) => {
    store.volume = value as number;
  };

export const psChangeRate =
  (value: number | number[]) => (store: PlayerStoreInterface) => {
    store.rate = value as number;
  };

export const psChangeAudioData =
  (audioDuration: number, currentTime: number) =>
  (store: PlayerStoreInterface) => {
    if (audioDuration === Infinity) audioDuration = 1;
    store.audioDuration = audioDuration;
    store.audioCurrentTime = currentTime;
    if (
      store.slug &&
      store?.listenedEpisodes &&
      store?.listenedEpisodes[store.slug]
    ) {
      store.listenedEpisodes[store.slug].status = currentTime;
    }
  };

export const psPlayPreviousPodcast = (store: PlayerStoreInterface) => {
  store.currentSource = store.previousSource;
  store.previousSource = radioSource;
  store.slug = store?.previousData?.slug || store.slug;
  store.title = store?.previousData?.title || store.title;
  store.subTitle = store?.previousData?.subTitle || store.subTitle;
  store.artwork = store?.previousData?.artwork || store.artwork;
  store.audioCurrentTime =
    store?.listenedEpisodes?.[store?.previousData?.slug]?.status;
  store.previousData = null;
};

export const psPlayPreviousRadio = (store: PlayerStoreInterface) => {
  store.previousSource = store.currentSource;
  store.previousData = {
    slug: store.slug,
    title: store.title,
    subTitle: store.subTitle,
    artwork: store.artwork,
    time: store.audioCurrentTime,
    audioDuration: store.audioDuration,
  };
  store.currentSource = radioSource;
  store.title = store.radioTitle;
  store.subTitle = store.radioSubTitle;
  store.artwork = store.radioArtwork;
  store.slug = "";
  store.audioCurrentTime = 1;
};

export const psLoadRadio = (store: PlayerStoreInterface) => {
  store.currentSource = "";
  store.title = store.radioTitle;
  store.subTitle = store.radioSubTitle;
  store.artwork = store.radioArtwork;
  store.audioCurrentTime = 1;
};

export const PlayerStore = new Store<PlayerStoreInterface>({
  // Elements
  audioElement: null,
  audioBuffer: null,
  // Sources
  radioSource: radioSource,
  currentSource: "",
  previousSource: radioSource,
  previousData: null,
  // UI
  hasBeenActivated: false,
  playerStatus: PlayerStatusTypes.Paused,
  showAdditionalControls: false,
  hidePlayer: false,
  activePlayerTab: PlayerTabTypes.Radio,
  uiVolume: [5],
  slug: "",
  podcast: { slug: "" },
  title: "",
  subTitle: "",
  artwork: "",
  authors: [],
  tags: [],
  publishedAt: "",
  // Audio
  volume: 0.5,
  rate: 1,
  audioDuration: 1,
  audioCurrentTime: 0,
  // Radio
  radioTitle: "newonce.radio",
  radioSubTitle: "Wciśnij play",
  radioArtwork: defaultRadioArtwork,
  radioArtworkLarge: defaultRadioArtwork,
  // SBM FFestival
  SBMFFestivalTitle: "SBM FFestival",
  SBMFFestivalSubTitle: "Włącz radio",
  // @todo: Think abour rearranging to radio stores?
  isSchedulePlaying: false,
  isScheduleAvailable: false,
  scheduleBelongsToPortal: false,
  scheduleTitle: "",
  scheduleArtworkUrl: "",
  scheduleSlug: "",
  scheduleStart: "",
  scheduleEnd: "",
  listenedEpisodes: null,
  placement: undefined,
});

PlayerStore.createReaction(
  (store) => store.currentSource,
  (currentSource, draft, original) => {
    let metadata: MediaMetaData;
    if (currentSource === radioSource) {
      metadata = {
        title: original.radioTitle,
        subTitle: original.radioSubTitle,
        artwork: original.radioArtworkLarge,
        slug: "",
        podcast: { slug: "" },
        tags: [],
      };
    } else {
      metadata = {
        title: original.title,
        subTitle: original.subTitle,
        artwork: original.artwork,
        slug: original.slug,
        podcast: { slug: original.podcast.slug },
        tags: original.tags,
      };
    }
    draft.rate = 1;

    draft.audioElement.setSource(
      currentSource,
      metadata,
      original.audioCurrentTime
    );
    if (currentSource === "") {
      draft.playerStatus = PlayerStatusTypes.Paused;
    } else {
      draft.playerStatus = PlayerStatusTypes.Loading;
    }
  }
);

PlayerStore.createReaction(
  (store) => store.audioCurrentTime,
  (currentTime, draft) => {
    if (
      draft.playerStatus === PlayerStatusTypes.Paused ||
      draft.playerStatus === PlayerStatusTypes.Loading
    ) {
      draft.audioElement.setCurrentTime(currentTime);
    }
  }
);

PlayerStore.createReaction(
  (store) => store.playerStatus,
  (playerStatus, draft, original) => {
    switch (playerStatus) {
      case PlayerStatusTypes.Paused: {
        draft.audioElement.pause();
        if (draft.currentSource !== radioSource) {
          window?.dataLayer?.push({
            event: "pause_on_demand",
            title: original.title,
            podcastTitle: original.subTitle,
            tags: `${original.tags}`,
            authors: `${original.authors}`,
            placement: original.placement,
          });
        } else {
          window?.dataLayer?.push({ event: "pause_on_air" });
        }
        break;
      }
      case PlayerStatusTypes.Loading: {
        draft.hasBeenActivated = true;
        const playPromise = draft.audioElement.play();
        if (!playPromise) break;
        playPromise
          .then(() => {
            PlayerStore.update((store) => {
              store.playerStatus = PlayerStatusTypes.Playing;
              store.hidePlayer = false;
            });
          })
          .catch(() => {
            PlayerStore.update((store) => {
              store.playerStatus = PlayerStatusTypes.Playing;
              store.hidePlayer = false;
            });
          });
        break;
      }

      case PlayerStatusTypes.Playing: {
        if (draft.currentSource !== radioSource) {
          window?.fbq("trackCustom", "On Demand Play", {
            title: draft.title,
            podcastTitle: draft.subTitle,
          });
          window?.dataLayer?.push({
            event: "play_on_demand",
            title: original.title,
            podcastTitle: original.subTitle,
            tags: `${original.tags}`,
            authors: `${original.authors}`,
            placement: original.placement,
          });
        } else {
          window?.fbq("trackCustom", "On Air Play", {
            title: draft.scheduleTitle,
          });
          window?.dataLayer?.push({
            event: "play_on_air",
            schedule: draft.scheduleTitle,
          });
        }
        break;
      }
    }
  }
);

PlayerStore.createReaction(
  (store) => store.volume,
  (volume, draft) => {
    draft.audioElement.setVolume(Number((volume[0] / 10).toFixed(1)));
  }
);

PlayerStore.createReaction(
  (store) => store.rate,
  (rate, draft) => {
    draft.audioElement.setRate(rate);
  }
);

PlayerStore.createReaction(
  (store) =>
    `${store.radioTitle}-${store.radioSubTitle}-${store.radioArtworkLarge}-${store.title}-${store.subTitle}-${store.artwork}-${store.playerStatus}`,
  (_, draft) => {
    if (
      draft.playerStatus === PlayerStatusTypes.Playing &&
      "mediaSession" in navigator
    ) {
      if (draft.currentSource === radioSource) {
        draft.audioElement.updateMetadata({
          title: draft.radioTitle,
          subTitle: draft.radioSubTitle,
          artwork: draft.radioArtworkLarge,
          podcast: { slug: "" },
          slug: "",
        });
      } else {
        draft.audioElement.updateMetadata({
          title: draft.title,
          subTitle: draft.subTitle,
          artwork: draft.artwork,
          podcast: { slug: draft.podcast.slug },
          slug: draft.slug,
        });
      }
    }
  }
);

PlayerStore.subscribe(
  (store) => store.audioElement,
  (audioElement) => {
    if (typeof window !== "undefined" && !!audioElement) {
    }
  }
);

registerInDevtools({
  PlayerStore,
});
