import client from "@/helpers/apiClient";
import { apiPath } from "@/helpers/apiPath";

export const setPosition = async (slug: string, status: number) => {
  try {
    await client.post(apiPath.LISTENED_POSITION(slug), { status });
  } catch (e) {
    console.log("e");
  }
};
