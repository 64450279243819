export const netFontFamily = {
  "--font-family-body": "HelveticaNowText-Regular",
  "--font-family-secondary": "Montserrat",
  "--font-family-button": "HelveticaNowText-Bold",
  "--font-family-bottom-nav": "HelveticaNowText-Bold",
  "--font-weight-secondary": "900",
};

export const sportFontFamily = {
  "--font-family-body": "HelveticaNowText-Regular",
  "--font-family-secondary": "urw-din-condensed",
  "--font-family-button": "urw-din-condensed",
  "--font-family-bottom-nav": "HelveticaNowText-Bold",
  "--font-weight-secondary": "500",
};

export const netTypography = {
  "--font-family-sections-heading": "Montserrat",
  "--font-style-sections-heading": "normal",
  "--font-weight-sections-heading": "900",
  "--font-family-heading": "HelveticaNowDisplayBlk",
  "--font-family-hero-desktop": "Montserrat",
  "--font-weight-hero-desktop": "900",
  "--heading-letter-spacing": "-0.01em",
  "--font-style-hx": "normal",
  "--font-size-hero-sm": "32px",
  "--font-size-hero": "36px",
  "--font-size-h1-sm": "28px",
  "--font-size-h1": "38px",
  "--font-size-h2-sm": "24px",
  "--font-size-h2": "32px",
  "--font-size-h3-sm": "18px",
  "--font-size-h3": "22px",
  "--font-size-h4-sm": "18px",
  "--font-size-h4": "24px",
  "--font-size-h5-sm": "16px",
  "--font-size-h5": "20px",
  "--font-size-h6-sm": "14px",
  "--font-size-h6": "16px",
  "--h6-letter-spacing": "0.01em",
  "--font-size-small": "12px",
  "--font-size-medium": "14px",
  "--font-size-large": "18px",
  "--font-size-large-sm": "16px",
  "--font-size-sections-heading": "36px",
  "--font-size-sections-heading-sm": "28px",
  "--font-size-search-empty-size-sm": "56px",
  "--font-size-search-empty-size": "80px",
  "--line-height-search-empty-sm": "68px",
  "--line-height-search-empty": "98px",
};

export const sportTypography = {
  "--font-family-sections-heading": "urw-din-condensed",
  "--font-style-sections-heading": "italic",
  "--font-weight-sections-heading": "600",
  "--font-family-heading": "urw-din-condensed",
  "--font-family-hero-desktop": "urw-din-condensed",
  "--font-weight-hero-desktop": "500",
  "--heading-letter-spacing": "0",
  "--font-style-hx": "italic",
  "--font-size-hero-sm": "32px",
  "--font-size-hero": "48px",
  "--font-size-h1-sm": "32px",
  "--font-size-h1": "44px",
  "--font-size-h2-sm": "24px",
  "--font-size-h2": "38px",
  "--font-size-h3-sm": "20px",
  "--font-size-h3": "28px",
  "--font-size-h4-sm": "20px",
  "--font-size-h4": "28px",
  "--font-size-h5-sm": "16px",
  "--font-size-h5": "20px",
  "--font-size-h6-sm": "16px",
  "--font-size-h6": "18px",
  "--h6-letter-spacing": "0",
  "--font-size-small": "12px",
  "--font-size-medium": "14px",
  "--font-size-large": "18px",
  "--font-size-large-sm": "16px",
  "--font-size-sections-heading": "36px",
  "--font-size-sections-heading-sm": "28px",
  "--font-size-search-empty-size-sm": "56px",
  "--font-size-search-empty-size": "80px",
};

export const netButtonsTypography = {
  "--font-size-button": "14px",
};

export const sportButtonsTypography = {
  "--font-size-button": "14px",
};
