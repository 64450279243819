import humps from "humps";

import client from "@/helpers/apiClient";
import { apiPath } from "@/helpers/apiPath";
import { PlayerStore } from "@/store/PlayerStore";
import { StatusType } from "@/helpers/pageHelpers/Store/interface";

export const getListenedEpisodes = async () => {
  try {
    const episodesStatuses = await client.get(apiPath.LISTENED_EPISODES);
    const { data } = episodesStatuses;
    const listenedEpisodes =
      data?.statuses &&
      humps.decamelizeKeys(data.statuses, {
        separator: "-",
        split: /(?=[A-Z0-9])/,
      });

    // const lastListenedEpisode = data?.last as PodcastEpisode;

    PlayerStore.update((store) => {
      store.listenedEpisodes = listenedEpisodes as {
        [key: string]: { status: number; status_type: StatusType };
      }[];
    });

    // if (lastListenedEpisode) {
    //   PlayerStore.update((store, original) => {
    //     if (!original.hasBeenActivated) {
    //       store.previousSource = `${lastListenedEpisode.media?.url}?api_token=${apiToken}`;
    //       store.title = lastListenedEpisode.title;
    //       store.subTitle = lastListenedEpisode.podcast.title;
    //       store.slug = lastListenedEpisode.slug;
    //       store.podcast.slug = lastListenedEpisode.podcast.slug;
    //       store.artwork = lastListenedEpisode.media.artwork;
    //       store.previousData = {
    //         title: lastListenedEpisode.title,
    //         subTitle: lastListenedEpisode.podcast.title,
    //         artwork: lastListenedEpisode.media.artwork,
    //         slug: lastListenedEpisode.slug,
    //         time: lastListenedEpisode.time,
    //         audioDuration: lastListenedEpisode?.media?.longVersionDuration,
    //       };
    //       store.audioCurrentTime =
    //         listenedEpisodes?.[lastListenedEpisode?.media?.slug]?.status;
    //       store.audioDuration = access
    //         ? lastListenedEpisode.media.longVersionDuration
    //         : lastListenedEpisode.media.duration;
    //       store.publishedAt = lastListenedEpisode?.publishedAt;
    //     }
    //   });
    // }

    return listenedEpisodes;
  } catch (e) {
    console.warn("e", e);
  }
};
