import React from "react";
import { useStoreState } from "pullstate";
import classnames from "classnames";

import IconButton from "@/components/IconButton";
import ForwardIcon from "@/icons/jumpForward.svg";
import BackwardIcon from "@/icons/jumpBackward.svg";
import ForwardIconLarge from "@/icons/jumpForwardLarge.svg";
import BackwardIconLarge from "@/icons/jumpBackwardLarge.svg";
import { JumpForwardBackwardButtonInterface } from "@/components/JumpForwardBackwardButton/JumpForwardBackwardButton.interface";
import { PlayerStore, psChangeTime } from "@/store/PlayerStore";

import styles from "./JumpForwardBackwardButton.module.scss";

export const JumpForwardBackwardButton: React.FC<
  JumpForwardBackwardButtonInterface
> = ({ direction, isLarge }) => {
  const { audioCurrentTime: currentTime } = useStoreState(
    PlayerStore,
    (store) => ({
      audioCurrentTime: store.audioCurrentTime,
    })
  );

  const options = {
    forward: {
      icon: ForwardIcon,
      iconLarge: ForwardIconLarge,
      interval: 15,
    },
    backward: {
      icon: BackwardIcon,
      iconLarge: BackwardIconLarge,
      interval: -15,
    },
  };

  const Icon = isLarge ? options[direction].iconLarge : options[direction].icon;

  const handleJump = () => {
    PlayerStore.update(psChangeTime(currentTime + options[direction].interval));
  };

  return (
    <IconButton
      className={classnames(styles.button, isLarge && styles.large, `${direction}-jump-button`)}
      onClick={handleJump}
    >
      <Icon />
    </IconButton>
  );
};

export default JumpForwardBackwardButton;
