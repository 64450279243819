import { useLayoutEffect, useRef, useState } from "react";
import { useStoreState } from "pullstate";
import classnames from "classnames";
import Link from "next/link";

import {
  PlayerStore,
  psPauseAudio,
  psPlayAudio,
  psPlayPreviousPodcast,
  radioSource,
} from "@/store/PlayerStore";
import Artwork from "@/components/Players/Artwork";
import AudioTimeline from "@/components/Players/AudioTimeline";
import IconButton from "@/components/IconButton";
import JumpForwardBackwardButton from "@/components/JumpForwardBackwardButton";
import PlaybackRateButton from "@/components/Players/PlaybackRateButton";
import Spinner from "@/components/Spinner";
import { JumpDirecton } from "@/components/JumpForwardBackwardButton/JumpForwardBackwardButton.interface";
import PauseIcon from "@/icons/rounded-pause-medium.svg";
import PlayIcon from "@/icons/rounded-play-medium.svg";
import { applicationRoutes } from "@/helpers/applicationRouting";
import { PlayerStatusTypes } from "@/helpers/pageHelpers/Common/interface";

import styles from "../DesktopPlayer.module.scss";

const PodcastPlayer = () => {
  const metaParentElement = useRef(null);
  const titleElement = useRef(null);
  const subtitleElement = useRef(null);
  const [metaParentWidth, setMetaParentWidth] = useState<number>(0);
  const [titleWidth, setTitleWidth] = useState<number>(0);
  const [subTitleWidth, setSubTitleWidth] = useState<number>(0);
  const {
    isPlaying,
    isLoading,
    isRadioSource,
    isCurrentSource,
    isAudioAvailable,
    listenedEpisodes,
    previousData,
    audioDuration,
    audioCurrentTime,
    title,
    subTitle,
    artwork,
    slug,
  } = useStoreState(PlayerStore, (store) => ({
    ...store,
    isPlaying: store.playerStatus === PlayerStatusTypes.Playing,
    isLoading: store.playerStatus === PlayerStatusTypes.Loading,
    isRadioSource: store.currentSource === radioSource,
    isPreviousPodcastAvailable: store.previousSource !== radioSource,
    isAudioAvailable: typeof window !== "undefined",
    isCurrentSource: !!store.currentSource.length,
    store: store,
  }));

  const pauseAudio = () => {
    PlayerStore.update(psPauseAudio);
  };

  const playAudio = () => {
    if (!isRadioSource && isCurrentSource) {
      PlayerStore.update(psPlayAudio);
    } else {
      PlayerStore.update(psPlayPreviousPodcast);
    }
  };

  const podcastTime =
    !isRadioSource && isCurrentSource
      ? audioCurrentTime
      : listenedEpisodes?.[previousData?.slug]?.status;
  const podcastDuration =
    !isRadioSource && isCurrentSource
      ? audioDuration
      : previousData?.audioDuration;
  const activePodcastTitle = isRadioSource ? previousData?.title : title;
  const activePodcastSubTitle = isRadioSource
    ? previousData?.subTitle
    : subTitle;
  const activePodcastArtwork = isRadioSource ? previousData?.artwork : artwork;

  useLayoutEffect(() => {
    setMetaParentWidth(metaParentElement.current.clientWidth);
    setTitleWidth(titleElement.current.scrollWidth);
    setSubTitleWidth(subtitleElement.current.scrollWidth);
  }, [
    activePodcastSubTitle,
    activePodcastTitle,
    subtitleElement,
    titleElement,
    metaParentElement,
  ]);

  return (
    <>
      <Artwork
        className={styles.artwork}
        artworkSrc={activePodcastArtwork}
        externalHref={`${applicationRoutes.PODCAST_EPISODE}[slug]`}
        externalLinkAs={
          applicationRoutes.PODCAST_EPISODE + (slug || previousData?.slug)
        }
      />
      <Link
        href={`${applicationRoutes.PODCAST_EPISODE}[slug]`}
        as={applicationRoutes.PODCAST_EPISODE + (slug || previousData?.slug)}
      >
        <a className={styles.link} ref={metaParentElement}>
          <div className={styles.meta}>
            <p
              ref={titleElement}
              className={classnames(
                styles.bold,
                styles.title,
                titleWidth > metaParentWidth && styles.marqueed,
              )}
            >
              {activePodcastTitle}
            </p>
            <p
              ref={subtitleElement}
              className={classnames(
                styles.title,
                subTitleWidth > metaParentWidth && styles.marqueed,
              )}
            >
              {activePodcastSubTitle}
            </p>
          </div>
        </a>
      </Link>
      <div key="podcast" className={styles.timelineContainer}>
        <div className={styles.controls}>
          <JumpForwardBackwardButton direction={JumpDirecton.Backward} />

          {isAudioAvailable && (
            <IconButton
              variant="round"
              size="medium"
              className={styles.playButton}
              onClick={isPlaying && !isRadioSource ? pauseAudio : playAudio}
            >
              {isLoading ? (
                <Spinner className={styles.spinner} />
              ) : isPlaying && !isRadioSource ? (
                <PauseIcon />
              ) : (
                <PlayIcon />
              )}
            </IconButton>
          )}

          <JumpForwardBackwardButton direction={JumpDirecton.Forward} />
        </div>
        <AudioTimeline
          className={styles.timeline}
          variant="standard"
          withElapsedTime
          podcastTime={podcastTime}
          podcastDuration={podcastDuration}
          podcastSlug={slug}
        />
        <PlaybackRateButton withMenu className={styles.rate} />
      </div>
    </>
  );
};

export default PodcastPlayer;
