import Feed from "shared/icons/feed.svg";
import FeedSmall from "shared/icons/feed-small.svg";
import Radio from "shared/icons/radio.svg";
import RadioSmall from "shared/icons/radio-small.svg";
import Podcast from "shared/icons/podcast.svg";
import PodcastSmall from "shared/icons/podcast-small.svg";
import Directory from "shared/icons/directory.svg";
import DirectorySmall from "shared/icons/directory-small.svg";
import Account from "shared/icons/account.svg";
import Search from "shared/icons/search.svg";
import Discord from "shared/icons/discord.svg";
import { applicationRouteNamespace } from "shared/helpers/applicationRouting";

export const routePath = {
  FEED: {
    href: "/",
    label: "Feed",
    icon: Feed,
    iconSmall: FeedSmall,
  },
  PERSONAL_FEED: {
    href: "/",
    label: "Mój Feed",
    icon: Feed,
  },
  FEED_FULL: {
    href: "/",
    label: "Wszystko",
    icon: null,
  },
  PERSONAL_FEED_FULL: {
    href: "/",
    label: "Wszystko",
    icon: null,
  },
  FEED_ARTICLES: {
    href: `/?feed=${applicationRouteNamespace.ARTICLE}`,
    label: "Artykuły",
    icon: null,
  },
  FEED_TAGS: {
    href: "/?feed=",
    label: "Tag",
    icon: null,
  },
  PERSONAL_FEED_ARTICLES: {
    href: `/?moje=${applicationRouteNamespace.ARTICLE}`,
    label: "Artykuły",
    icon: null,
  },
  ARTICLE: {
    href: "/artykul/[slug]",
    label: "Artykuł",
  },
  FEED_PODCAST: {
    href: `/?feed=${applicationRouteNamespace.PODCAST}`,
    label: "Podcasty",
    icon: null,
  },
  FEED_VIDEO: {
    href: `/?feed=${applicationRouteNamespace.VIDEO}`,
    label: "Wideo",
    icon: null,
  },
  PERSONAL_FEED_VIDEO: {
    href: `/?moje=${applicationRouteNamespace.VIDEO}`,
    label: "Wideo",
    icon: null,
  },
  PERSONAL_FEED_PODCAST: {
    href: `/?moje=${applicationRouteNamespace.PODCAST}`,
    label: "Epizody Podcastów",
    icon: null,
  },
  TAG: {
    href: "/tag/[slug]",
    label: "Tag",
    icon: null,
  },
  RADIO: {
    href: "/radio",
    label: "Radio",
    icon: Radio,
    iconSmall: RadioSmall,
  },
  RADIO_LIVE: {
    href: "/radio",
    label: "Live",
    icon: Radio,
  },
  RADIO_SBM: {
    href: "/radio/sbm-ffestival",
    label: "SBM FFestival",
    icon: Radio,
  },
  RADIO_TRACKLIST: {
    href: "/radio/tracklista",
    label: "Tracklista",
    icon: null,
  },
  RADIO_SCHEDULE: {
    href: "/radio/ramowka",
    label: "Ramówka",
    icon: null,
  },
  PODCASTS: {
    href: "/podcasty",
    label: "Podcasty",
    icon: Podcast,
    iconSmall: PodcastSmall,
  },
  PODCASTS_FOLLOWED: {
    href: "/podcasty/obserwowane",
    label: "Obserwowane",
    icon: Podcast,
  },
  PODCASTS_BOOKMARED: {
    href: "/podcasty/zapisane",
    label: "Zapisane",
    icon: Podcast,
  },
  PODCAST: {
    href: "/podcast/[slug]",
    label: "Podcast",
    icon: Podcast,
  },
  VIDEO: {
    href: "/wideo/[slug]",
    label: "Wideo",
    icon: null,
  },
  PODCAST_EPISODE: {
    href: "/epizod/[slug]",
    label: "Epizod",
  },
  DIRECTORY: {
    href: "/katalog",
    label: "Katalog",
    icon: Directory,
  },
  ACCOUNT: {
    href: "/konto/obserwowane",
    label: "Mój profil",
    icon: Account,
  },
  ACCOUNT_SETTINGS: {
    href: "/konto/ustawienia",
    label: "Ustawienia",
    icon: Account,
  },
  ACCOUNT_FOLLOWED: {
    href: "/konto/obserwowane",
    label: "Obserwowane",
    icon: Account,
  },
  ACCOUNT_BOOKMARKED: {
    href: "/konto/zapisane",
    label: "Zapisane",
    icon: Account,
  },
  HELP: {
    href: "/pomoc",
    label: "Pomoc",
    icon: null,
  },
  CONTACT: {
    href: "/kontakt",
    label: "Kontakt",
    icon: null,
  },
  AUTHORS: {
    href: "/redaktorzy",
    label: "Redakcja",
    icon: null,
  },
  AUTHOR: {
    href: "/redaktor/[slug]",
    label: "Redaktor",
    icon: null,
  },
  REGISTER: {
    href: "/rejestracja",
    label: "Rejestracja",
    icon: null,
  },
  ONBOARDING: {
    href: "/onboarding",
    label: "Onboarding",
    icon: null,
  },
  ONBOARDING_PERSONALIZE: {
    href: "/personalizacja",
    label: "Personalizacja",
    icon: null,
  },
  LOGIN: {
    href: "/logowanie",
    label: "Logowanie",
    icon: null,
  },
  RESET: {
    href: "/odzyskaj",
    label: "Reset hasła",
    icon: null,
  },
  REMOVE_ACCOUNT: {
    href: "/konto/usun",
    label: "Usuń konto",
    icon: null,
  },
  SEARCH: {
    href: "/szukajka",
    label: "Szukaj",
    icon: Search,
  },
  PAGE: {
    href: "/[slug]",
    label: "Strona",
    icon: null,
  },
  MUSIC: {
    href: "/baza-muzyczna",
    label: "Baza muzyczna",
    icon: Directory,
    iconSmall: DirectorySmall,
  },
  ARTISTS: {
    href: "/baza-muzyczna/artysci",
    label: "Artyści",
    icon: null,
  },
  ARTIST: {
    href: "/baza-muzyczna/artysta/[slug]",
    label: "Artysta",
    icon: null,
  },
  RELEASES: {
    href: "/baza-muzyczna/wydawnictwa",
    label: "Wydawnictwa",
    icon: null,
  },
  RELEASE: {
    href: "/baza-muzyczna/wydawnictwo/[slug]",
    label: "Wydawnictwo",
    icon: null,
  },
  HIT_LIST: {
    href: "/lista-przebojow",
    label: "Lista przebojów",
    icon: null,
  },
  RAP_HIT_LIST: {
    href: "/rapowy-top-wszech-czasow",
    label: "Rapowy Top",
    icon: null,
  },
  DISCORD: {
    href: "https://discord.gg/g4QP7fMC6h",
    label: "Discord",
    icon: Discord,
  },
};

export const feedPaths = [
  routePath.FEED.href,
  routePath.FEED_FULL.href,
  routePath.FEED_ARTICLES.href,
  routePath.FEED_PODCAST.href,
  routePath.ARTICLE.href,
  routePath.TAG.href,
];

export const feedSubNavPaths = [
  routePath.FEED.href,
  routePath.FEED_FULL.href,
  routePath.FEED_ARTICLES.href,
  routePath.FEED_PODCAST.href,
];

export const radioPaths = [
  routePath.RADIO.href,
  routePath.RADIO_SBM.href,
  routePath.RADIO_LIVE.href,
  routePath.RADIO_TRACKLIST.href,
  routePath.RADIO_SCHEDULE.href,
  routePath.HIT_LIST.href,
  routePath.RAP_HIT_LIST.href,
];

export const podcastPaths = [
  routePath.PODCASTS.href,
  routePath.PODCASTS_FOLLOWED.href,
  routePath.PODCASTS_BOOKMARED.href,
  routePath.PODCAST.href,
  routePath.PODCAST_EPISODE.href,
];

export const authPaths = [
  routePath.REGISTER.href,
  routePath.LOGIN.href,
  routePath.ONBOARDING.href,
  routePath.RESET.href,
];

export const accountPaths = [
  routePath.ACCOUNT.href,
  routePath.ACCOUNT_SETTINGS.href,
  routePath.ACCOUNT_FOLLOWED.href,
  routePath.ACCOUNT_BOOKMARKED.href,
];

export const customRoutePaths = [routePath.SEARCH.href];

export const hiddenBottomBar = [routePath.SEARCH.href];

export const mobileSubscribeButtonPaths = [
  ...feedPaths,
  ...radioPaths,
  routePath.PODCASTS.href,
  routePath.PODCASTS_FOLLOWED.href,
  routePath.PODCASTS_BOOKMARED.href,
];
