import { parseCookies, destroyCookie } from "nookies";
import axios, {
  AxiosRequestTransformer,
  AxiosResponseTransformer,
} from "axios";
import humps from "humps";

export const apiClient = (context?: any) => {
  const instance = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_API_PATH}/portals/${process.env.NEXT_PUBLIC_PORTAL}/`,
    transformResponse: [
      ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
      (data) => humps.camelizeKeys(data),
    ],
    transformRequest: [
      (data) => humps.decamelizeKeys(data),
      ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
    ],
  });

  instance.interceptors.request.use((config) => {
    const cookies = parseCookies(context);
    const token = cookies.token;

    if (token) {
      config.headers.setAuthorization(`Bearer ${token}`);
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        delete instance.defaults.headers.common["Authorization"];
        destroyCookie(context, "token");

        return axios.request(error.config);
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const apiClientV2 = (context?: any) => {
  const instance = axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_API_V2_PATH}/portals/${process.env.NEXT_PUBLIC_PORTAL}/`,
    transformResponse: [
      ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
      (data) => humps.camelizeKeys(data),
    ],
    transformRequest: [
      (data) => humps.decamelizeKeys(data),
      ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
    ],
  });

  instance.interceptors.request.use((config) => {
    const cookies = parseCookies(context);
    const token = cookies.token;

    if (token) {
      config.headers.setAuthorization(`Bearer ${token}`);
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        delete instance.defaults.headers.common["Authorization"];
        destroyCookie(context, "token");
      }
    }
  );

  return instance;
};

export default apiClient();
