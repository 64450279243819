import { registerInDevtools, Store } from "pullstate";

import {
  AuthFormState,
  FeedType,
  ScrollDirection,
  SearchCategory,
  UIStoreInterface,
} from "@/helpers/pageHelpers/Store/interface";

export const UIStore = new Store<UIStoreInterface>({
  hideSubMenus: false,
  clampNavHeader: false,
  menuHeight: 130,
  feedType: FeedType.Main,
  userOnboarded: false,
  userLoggedIn: false,
  firstRender: true,
  authModalOpen: false,
  authFormState: AuthFormState.Login,
  alternateColors: false,
  searchInitiated: false,
  searchCategory: SearchCategory.Best,
  searchResults: {},
  perksModalStep: 1,
  articleSlug: "",
  articleBookmarked: false,
  articleScrollDirection: ScrollDirection.Up,
  articleLength: 0,
  articleScrollPositionY: 0,
  articleScrollRelativeDistance: 0,
  commentsCount: 0,
  oneSingalId: "",
  oneSingalTags: [],
  userID: undefined,
  bookmarkedEpisodes: null,
  isShareModalOpen: false,
});

registerInDevtools({
  UIStore,
});
