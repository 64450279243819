export const defaultPageItems = {
  MAIN_FEED: 25,
  PERSONAL_FEED: 25,
  SPECIFIC_FEED: 23,
  VIDEO_FEED: 12,
  PODCASTS: 15,
  FOLLOWED_SUBTAGS: 12,
  FOLLOWED_PODCASTS: 15,
  FOLLOWED_AUTHORS: 6,
  SEARCH_DEFAULT: 32,
  RELATED_CONTENT: 8,
  MUSIC_DEFAULT: 36,
  COMMENTS: 12,
  USER_CONTENT: 7,
};

export const emptySorting = {
  field: "",
  direction: "",
};

export const ascSorting = {
  field: "",
  direction: "asc",
};

export const descSorting = {
  field: "",
  direction: "desc",
};
