export const netLightColors = {
  "--surface-primary": "#f4f7f9",
  "--surface-secondary": "#ffffff",
  "--surface-tertiary": "#ffffff",

  "--content-primary": "#000000",
  "--content-secondary": "#ffffff",
  "--content-tertiary": "#868E94",

  "--gray-g25": "#eeeeee",
  "--gray-g50": "#f1f3f3",
  "--gray-g100": "#e5eaec",
  "--gray-g200": "#dce2e5",
  "--gray-g300": "#d5dce0",
  "--gray-g400": "#cbd2d6",
  "--gray-g500": "#c0c7cc",
  "--gray-g600": "#b9c0c5",
  "--gray-g700": "#afb7bd",
  "--gray-g800": "#a4afb7",
  "--gray-g900": "#96a0a8",

  "--primary-p50": "#ebeeff",
  "--primary-p100": "#d5dbfe",
  "--primary-p200": "#8597ff",
  "--primary-p300": "#5871fe",
  "--primary-p400": "#3d5bf9",
  "--primary-p500": "#2548ff",
  "--primary-p600": "#1300e8",
  "--primary-p700": "#1200da",
  "--primary-p800": "#1100cb",
  "--primary-p900": "#1102b2",

  "--foundation-on-primary": "#ffffff",
  "--foundation-on-primary-inverse": "#000000",

  "--link-on-surface": "#2548ff",
  "--link-on-surface-hover": "#1300e8",

  "--tag-on-surface": "#D3DBFA",
  "--tag-on-surface-hover": "#C3CDFA",
  "--tag-on-surface-pressed": "#B2BFFB",

  "--topnav-player":
    "radial-gradient(99.07% 574.8% at 98.91% 4.96%, #00D2FD 4.42%, #A469F4 53.58%, #DF6097 100%)",
  "--bottom-nav-background": "rgba(255, 255, 255, 0.94)",
  "--bottom-nav-active": "#2548FF",
  "--bottom-nav-inactive": "#868E94",
  "--topbar-box-shadow": "0px 1px 0px #EEEEEE",
  "--navbar-color-active": "#FFFFFF",
  "--logo": "#000",
  "--logo-bar": "#fff",
  "--logo-bar-background": "#202226",
  "--navbar-shadow-left":
    "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 76%)",
  "--navbar-shadow-right":
    "linear-gradient(270deg, #FFFFFF 26%, rgba(255, 255, 255, 0) 100%)",
  "--follow-author-button-background": "#F1F3F3",
  "--follow-author-button-color": "#000000",
  "--follow-author-button-background-active": "#F4F7F9",
  "--follow-author-button-color-active": "#2548FF",
  "--follow-author-button-background-hover": "#E5EAEC",
  "--follow-author-button-color-hover": "#000000",
  "--primary-button-stroke-background-active": "#1300E80A",
  "--secondary-button-stroke-background-active": "#2548FF0A",
  "--tertiary-button-stroke-background-active": "#2548FF14",
};

export const netDarkColors = {
  "--surface-primary": "#2a2d31",
  "--surface-secondary": "#202226",
  "--surface-tertiary": "#35373a",

  "--content-primary": "#ffffff",
  "--content-secondary": "#000000",
  "--content-tertiary": "#9ca0a5",

  "--gray-g25": "#37383b",
  "--gray-g50": "#4c4e52",
  "--gray-g100": "#4e5156",
  "--gray-g200": "#53565a",
  "--gray-g300": "#5a5e62",
  "--gray-g400": "#616568",
  "--gray-g500": "#6e7174",
  "--gray-g600": "#7c7f82",
  "--gray-g700": "#878b8e",
  "--gray-g800": "#94989b",
  "--gray-g900": "#a6a9ac",

  "--primary-p50": "#78a7ff",
  "--primary-p400": "#5481fe",
  "--primary-p500": "#2f66ff",
  "--primary-p600": "#295eef",
  "--primary-p700": "#0d48eb",

  "--foundation-on-primary": "#ffffff",
  "--foundation-on-primary-inverse": "#ffffff",

  "--link-on-surface": "#668fff",
  "--link-on-surface-hover": "#4b7bff",

  "--tag-on-surface": "#3E4C70",
  "--tag-on-surface-hover": "#334269",
  "--tag-on-surface-pressed": "#334679",

  "--topnav-player":
    "radial-gradient(99.07% 574.8% at 98.91% 4.96%, #00D2FD 4.42%, #A469F4 53.58%, #DF6097 100%)",
  "--bottom-nav-background": "rgba(53, 55, 58, 0.94)",
  "--bottom-nav-active": "#2F66FF",
  "--bottom-nav-inactive": "#9CA0A5",
  "--topbar-box-shadow": "0px 1px 0px #37383B",
  "--navbar-color-active": "#FFFFFF",
  "--logo": "#fff",
  "--logo-bar": "#fff",
  "--logo-bar-background": "#202226",
  "--navbar-shadow-left":
    "linear-gradient(270deg, rgba(32, 34, 38, 0) 0%, #202226 76%)",
  "--navbar-shadow-right":
    "linear-gradient(270deg, #202226 26%, rgba(32, 34, 38, 0) 100%)",
  "--follow-author-button-background": "#4C4E52",
  "--follow-author-button-color": "#FFFFFF",
  "--follow-author-button-background-active": "#2A2D31",
  "--follow-author-button-color-active": "#2F66FF",
  "--follow-author-button-background-hover": "#4E5156",
  "--follow-author-button-color-hover": "FFFFFF",
  "--primary-button-stroke-background-active": "#4B7BFF0A",
  "--secondary-button-stroke-background-active": "#668FFF0A",
  "--tertiary-button-stroke-background-active": "#668FFF14",
};

export const sportLightColors = {
  "--surface-primary": "#f5f5f5",
  "--surface-secondary": "#ffffff",
  "--surface-tertiary": "#ffffff",

  "--content-primary": "#000000",
  "--content-secondary": "#ffffff",
  "--content-tertiary": "#606060",

  "--gray-g25": "#f0f0f0",
  "--gray-g50": "#f1f1f1",
  "--gray-g100": "#e9e9e9",
  "--gray-g200": "#e6e6e6",
  "--gray-g300": "#cccccc",
  "--gray-g400": "#b2b2b2",
  "--gray-g500": "#999999",
  "--gray-g600": "#7c7f82",
  "--gray-g700": "#878b8e",
  "--gray-g800": "#94989b",
  "--gray-g900": "#a6a9ac",

  "--primary-p400": "#f0fd79",
  "--primary-p500": "#e7ff00",
  "--primary-p600": "#dbf108",
  "--primary-p700": "#d3e900",

  "--foundation-on-primary": "#000000",
  "--foundation-on-primary-inverse": "#7a8702",

  "--link-on-surface": "#6c760b",
  "--link-on-surface-hover": "#5a6305",

  "--tag-on-surface": "#DFE1D0",
  "--tag-on-surface-hover": "#D4D6BD",
  "--tag-on-surface-pressed": "#C9CCAA",

  "--topnav-player":
    "radial-gradient(99.07% 1194.25% at 98.91% 4.96%, #E8B5E1 2.08%, #ABDDAD 42.19%, #ECFA36 100%)",
  "--bottom-nav-background": "rgba(255, 255, 255, 0.94)",
  "--bottom-nav-active": "#000000",
  "--bottom-nav-inactive": "#8D8D8D",
  "--topbar-box-shadow": "0px 1px 0px #F0F0F0",
  "--navbar-color-active": "#000000",
  "--logo": "#000000",
  "--logo-bar": "#E7FF00",
  "--logo-bar-background": "#151515",
  "--navbar-shadow-left":
    "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 76%)",
  "--navbar-shadow-right":
    "linear-gradient(270deg, #FFFFFF 26%, rgba(255, 255, 255, 0) 100%)",
  "--follow-author-button-background": "#F1F1F1",
  "--follow-author-button-color": "#000000",
  "--follow-author-button-background-active": "#242424",
  "--follow-author-button-color-active": "#E7FF00",
  "--follow-author-button-background-hover": "#E9E9E9",
  "--follow-author-button-color-hover": "#000000",
  "--primary-button-stroke-background-active": "#5A63050A",
  "--secondary-button-stroke-background-active": "#6C760B0A",
  "--tertiary-button-stroke-background-active": "#6C760B14",
};

export const sportDarkColors = {
  "--surface-primary": "#242424",
  "--surface-secondary": "#151515",
  "--surface-tertiary": "#2e2e2e",

  "--content-primary": "#ffffff",
  "--content-secondary": "#000000",
  "--content-tertiary": "#8d8d8d",

  "--gray-g25": "#313131",
  "--gray-g50": "#4b4b4b",
  "--gray-g100": "#505051",
  "--gray-g200": "#5d5d5e",
  "--gray-g300": "#6a6a6b",
  "--gray-g400": "#737374",
  "--gray-g500": "#7b7b7c",
  "--gray-g600": "#8b8b8b",
  "--gray-g700": "#9a9999",
  "--gray-g800": "#ababab",
  "--gray-g900": "#b7b6b6",

  "--primary-p400": "#f0fd79",
  "--primary-p500": "#e7ff00",
  "--primary-p600": "#dbf108",
  "--primary-p700": "#d3e900",

  "--foundation-on-primary": "#000000",
  "--foundation-on-primary-inverse": "#7a8702",

  "--link-on-surface": "#e7ff00",
  "--link-on-surface-hover": "#f7ffaa",

  "--tag-on-surface": "#43471E",
  "--tag-on-surface-hover": "#52581B",
  "--tag-on-surface-pressed": "#626A18",

  "--topnav-player":
    "radial-gradient(99.07% 1194.25% at 98.91% 4.96%, #E8B5E1 2.08%, #ABDDAD 42.19%, #ECFA36 100%)",
  "--bottom-nav-background": "rgba(46, 46, 46, 0.94)",
  "--bottom-nav-active": "#E7FF00",
  "--bottom-nav-inactive": "#8D8D8D",
  "--topbar-box-shadow": "0px 1px 0px #313131",
  "--navbar-color-active": "#000000",
  "--logo": "#E7FF00",
  "--logo-bar": "#E7FF00",
  "--logo-bar-background": "#151515",
  "--navbar-shadow-left":
    "linear-gradient(270deg, rgba(21, 21, 21, 0) 0%, #151515 76%)",
  "--navbar-shadow-right":
    "linear-gradient(270deg, #151515 26%, rgba(21, 21, 21, 0) 100%)",
  "--follow-author-button-background": "#4B4B4B",
  "--follow-author-button-color": "#FFFFFF",
  "--follow-author-button-background-active": "#242424",
  "--follow-author-button-color-active": "#E7FF00",
  "--follow-author-button-background-hover": "#505051",
  "--follow-author-button-color-hover": "#FFFFFF",
  "--primary-button-stroke-background-active": "#F7FFAA0A",
  "--secondary-button-stroke-background-active": "#E7FF000A",
  "--tertiary-button-stroke-background-active": "#E7FF0014",
};
