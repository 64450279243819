import React from "react";
import classnames from "classnames";
import { Range } from "react-range";
import { useStoreState } from "pullstate";
import { getTrackBackground } from "react-range";

import {
  PlayerStore,
  psChangeUiVolume,
  psChangeVolume,
} from "shared/store/PlayerStore";
import styles from "shared/components/Players/DesktopPlayer/DesktopPlayer.module.scss";

import commonStyles from "../Common.module.scss";

import { VolumeMixerInterface } from "./VolumeMixer.interface";

export const VolumeMixer: React.FC<VolumeMixerInterface> = ({ className }) => {
  const uiVolume = useStoreState(PlayerStore, (store) => store.uiVolume);
  const volumeValue = uiVolume.map((value) => (value < 0 ? 0 : value));

  const changeVolume = (value) => {
    PlayerStore.update(psChangeUiVolume(value));
    PlayerStore.update(psChangeVolume(value));
  };

  return (
    <div className={classnames(styles.volumeMixer, className)}>
      <div className={commonStyles.mixerWrapper}>
        <Range
          values={volumeValue}
          step={1}
          min={0}
          max={10}
          onFinalChange={changeVolume}
          onChange={changeVolume}
          renderTrack={({ props, children }) => (
            <div
              className={commonStyles.mixerTrack}
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{ ...props.style }}
            >
              <div
                className={commonStyles.mixerProgress}
                ref={props.ref}
                style={{
                  background: getTrackBackground({
                    values: uiVolume,
                    colors: ["var(--color-player-controls)", "transparent"],
                    min: 0,
                    max: 10,
                  }),
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              className={commonStyles.mixerThumb}
              style={{ ...props.style }}
            >
              <div className={commonStyles.mixerTooltip}>
                {uiVolume[0] > 0 ? uiVolume : "Wycisz"}
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default VolumeMixer;
